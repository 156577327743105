import {
    CreateVehicleDisposalInfo,
    getAuctionLocationList,
    getVechileDisposalInfo,
    UpdateVehicleDisposalInfo
} from "../api/assignmentsummary.api.js";

export const AccountSummaryService = {

  getVechileDisposalInfo : async (customerAccoutNumber,cobusCode,origBranch) => {
        return getVechileDisposalInfo(customerAccoutNumber,cobusCode,origBranch);
     },
     CreateVehicleDisposalInfo: async (insertvehicladisposaldata) => {
        return CreateVehicleDisposalInfo(insertvehicladisposaldata);
     },
     UpdateVehicleDisposalInfo: async (customerAccoutNumber,cobusCode,origBranch,updatevehicleDisposaldata1) => {
        return UpdateVehicleDisposalInfo(customerAccoutNumber,cobusCode,origBranch,updatevehicleDisposaldata1);
     },
   getAuctionLocationList :async(countrycode) =>{
         return getAuctionLocationList();
      }
   }
