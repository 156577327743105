import {useEffect, useState} from "react";

const PriceStruct = ({prcStrctData: initPrcStrctData, onUpdate}) => {
    const [prcStrctData, setPrcStrctData] = useState(initPrcStrctData);

    useEffect(()=>{
        onUpdate(prcStrctData.psId, 'status', 'update', {});
    }, [prcStrctData]);

    const handleOnChange = (e) => {
        const {name, value} = e.target;
        const currPrcStruct = {...prcStrctData, [name]: value};
        setPrcStrctData(currPrcStruct);
        onUpdate(prcStrctData.psId, getDataFieldName(name), value, valiateField(name, value, e.target.getAttribute('messagename')));
    };

    const handleClearPriceStruct = (e) => {
        const currPrcStruct = {
            ...prcStrctData,
            status: 'remove'
        };
        setPrcStrctData(currPrcStruct);
        onUpdate(prcStrctData.psId, 'status', 'remove', {});
    };

    const valiateField = (name, value, messagename) => {
        let errorObj = {};
        let error = '';
        switch (name) {
            case 'name':
                error = value !== '' ? '': messagename + " cannot be blank.";
                break;
            case 'selfHelpAmt': case 'volFeeAmt': case 'dealerVolFeeAmt': case 'closeFeeAmt':
                error = value !== '' ? '': messagename + " cannot be blank.";
                if(error === '') {
                    error = !isNaN(Number(value)) ? "" : messagename + " is not a number";
                }
                break;
            default:
                break;
        }
        if(error !== '') {
            errorObj[getFieldName(name)] = error;
        }
        return errorObj;
    };

    const getFieldName = (name) => {
        switch (name) {
            case 'name':
                return 'prcStrctTx-' + prcStrctData.psId;
            case 'selfHelpAmt':
                return 'feeAm-' + prcStrctData.psId;
            case 'volFeeAmt':
                return 'vlntrFeeAm-' + prcStrctData.psId;
            case 'dealerVolFeeAmt':
                return 'vlntrTodlrFeeAm-' + prcStrctData.psId;
            default:
                return name + '-' + prcStrctData.psId;
        }
    };

    const getDataFieldName = (name) => {
        switch (name) {
            case 'name':
                return 'prcStrctTx';
            case 'selfHelpAmt':
                return 'feeAm';
            case 'volFeeAmt':
                return 'vlntrFeeAm';
            case 'dealerVolFeeAmt':
                return 'vlntrTodlrFeeAm';
            case 'closeFeeAmt':
                return 'clsFeeAm';
            default:
                return name;
        }
    }
    const handleSelection = (e) => {
        const {name, value} = e.target;
        setPrcStrctData(prevState => ({...prevState, gvwTypeCd: value}));
        onUpdate(prcStrctData.psId, name, value, {});
    };
    return (
        <>
            <tr>
                <td>
                    <label>Price Structure Name:</label>
                </td>
                <td>
                    <table><tbody>
                    <tr>
                        <td style={{border: "none", paddingLeft: "0rem"}}>
                            <input type="text"
                                   name="name"
                                   className="fmc-input"
                                   value={prcStrctData.name || ''}
                                   messagename="Price Structure Name"
                                   onChange={handleOnChange}/></td>
                        <td style={{border: "none"}}>
                            <div className="fmc-check">
                                <label>
                                    <input type="checkbox" name="remove" value={prcStrctData.remove}
                                           onChange={handleClearPriceStruct}/>
                                    <span>Clear this pricing structure</span>
                                </label>
                            </div>
                        </td>
                    </tr>
                    </tbody></table>
                </td>
            </tr>
            <tr>
                <td>
                    <label>Self Help Amount:</label>
                </td>
                <td>
                    <input type="text"
                           name="selfHelpAmt"
                           className="fmc-input"
                           value={prcStrctData.selfHelpAmt || ''}
                           messagename="Contractor Fee amount"
                           onChange={handleOnChange}/>
                </td>
            </tr>
            <tr>
                <td>
                    <label>Voluntary Contractor Fee Amount:</label>
                </td>
                <td>
                    <input type="text"
                           name="volFeeAmt"
                           className="fmc-input"
                           value={prcStrctData.volFeeAmt || ''}
                           messagename="Voluntary contractor Fee amount"
                           onChange={handleOnChange}/>
                </td>
            </tr>
            <tr>
                <td>
                    <label>Dealer Voluntary Contractor Fee Amount:</label>
                </td>
                <td>
                    <input type="text"
                           name="dealerVolFeeAmt"
                           className="fmc-input"
                           value={prcStrctData.dealerVolFeeAmt || ''}
                           messagename="Dealer Voluntary contractor Fee amount"
                           onChange={handleOnChange}/>
                </td>
            </tr>
            <tr>
                <td>
                    <label>Close Fee Amount:</label>
                </td>
                <td>
                    <input type="text"
                           name="closeFeeAmt"
                           className="fmc-input"
                           value={prcStrctData.closeFeeAmt || '0'}
                           onChange={handleOnChange}/>
                </td>
            </tr>
            <tr>
                <td>
                    <label>Contingency Indicator:</label>
                </td>
                <td>
                    <select name="cntngntIn" id="cntngntIn" value={prcStrctData.cntngntIn}
                            className="fmc-select"
                            style={{width: '5em'}}
                            onChange={handleSelection}>
                        <option value="C">C</option>
                        <option value="N">N</option>
                    </select>
                </td>
            </tr>
            <tr>
                <td>
                    <label>Delivery to Auction Indicator:</label>
                </td>
                <td>
                    <select name="dlvryToAuctIn" id="dlvryToAuctIn" size="1" value={prcStrctData.dlvryToAuctIn}
                            className="fmc-select"
                            style={{width: '5em'}}
                            onChange={handleSelection}>
                        <option value="D">D</option>
                        <option value="N">N</option>
                    </select>
                </td>
            </tr>
            <tr>
                <td>
                    <label>GVW Indicator:</label>
                </td>
                <td>
                    <select name="gvwTypeCd" id="gvwTypeCd" size="1" value={prcStrctData.gvwTypeCd}
                            className="fmc-select"
                            style={{width: '5em'}}
                            onChange={handleSelection}>
                        <option value="M">M</option>
                        <option value="H">H</option>
                        <option value="A">A</option>
                        <option value="N">N</option>
                    </select>
                </td>
            </tr>
        </>
    );
};

export default PriceStruct;