// required
import '@ford/ford-design-system/src/web-components/fmc-forms/fmc-control.js';

// choose a input component
import '@ford/ford-design-system/src/web-components/fmc-forms/fmc-input.js';
import '@ford/ford-design-system/src/web-components/fmc-forms/fmc-select.js';
import '@ford/ford-design-system/src/web-components/fmc-forms/fmc-textarea.js';

// optional sub components
import '@ford/ford-design-system/src/web-components/fmc-forms/fmc-count.js';
import '@ford/ford-design-system/src/web-components/fmc-forms/fmc-combo.js';
import './Summary.css';

function PersonalProprtySum() {
  return (

    <div className='details_cntr'>

      <div id='invoiceheader' className='center' >
        PERSONAL PROPERTY
      </div>
      <div id="persnprptbl_cntr">
        <section
          className="fmc-global-attribute-bar fmc-global-attribute-bar--vr-md fmc-px-3 fmc-pb-3-sm fmc-py-3-md fmc-py-3-lg fmc-py-3-xl fds-color__bg--white"
        >
          <section
            className="fmc-global-attribute-bar fmc-global-attribute-bar--vr-md fmc-px-3 fmc-pb-3-sm fmc-py-3-md fmc-py-3-lg fmc-py-3-xl fds-color__bg--white"
          >
            <div >
              Personal property recoverd in  vehicle when recovered

            </div>
            <div><fieldset class="fmc-check fmc-check--horizontal">

              <label>
                <input type="radio" name="radio-button-items" value="item-1" checked="" />
                <span>Yes</span>
              </label>

              <label>
                <input type="radio" name="radio-button-items" value="item-3" disabled="" />
                <span>No</span>
              </label>

            </fieldset>
            </div>
            <div>

              If yes  itemize  the following form and  when returned to customer have him sign in space provided.
            </div>
          </section>

          <section

            className="fmc-global-attribute-bar fmc-global-attribute-bar--vr-md fmc-px-3 fmc-pb-3-sm fmc-py-3-md fmc-py-3-lg fmc-py-3-xl fds-color__bg--white"
          >

            <div><fieldset class="fmc-check fmc-check--horizontal">

              <label>
                <input type="radio" name="radio-button-items" value="item-1" checked="" />
                <span>Personal property returned</span>
              </label>

              <label>
                <input type="radio" name="radio-button-items" value="item-3" disabled="" />
                <span>Personal Property disposed</span>
              </label>

            </fieldset>
            </div>
          </section>

        <div id="personpro_itm_cntr">
          <table class="fmc-table" >
            <thead>
              <tr>

              </tr>
            </thead>
            <tbody>

              <tr>
                <td>1</td>
                <td>2</td>
                <td>3</td>
              </tr>
              <tr>
                <td>4</td>
                <td>5</td>
                <td>6</td>
              </tr>
              <tr>
                <td>7</td>
                <td>8</td>
                <td>9</td>
              </tr>
              <tr>
                <td>10</td>
                <td>11</td>
                <td>12</td>
              </tr>
              <tr>
                <td>13</td>
                <td>14</td>
                <td>15</td>
              </tr>
              <tr>
                <td>Where Property stored</td>
                <td>Naming of Storing contractor</td>
                <td>I acknowledge return of listed items
                  <br />

                  -------------------------<br />
                  Customer's Name and date
                </td>
              </tr>

            </tbody>
          </table>
          </div>
        </section>
      </div>
        <div> PERSONAL  PROPERTY & TRUST RECEIPT </div>
    </div>
  );

}

export default PersonalProprtySum;