// required
import '@ford/ford-design-system/src/web-components/fmc-forms/fmc-control.js';

// choose a input component
import '@ford/ford-design-system/src/web-components/fmc-forms/fmc-input.js';
import '@ford/ford-design-system/src/web-components/fmc-forms/fmc-select.js';
import '@ford/ford-design-system/src/web-components/fmc-forms/fmc-textarea.js';

// optional sub components
import '@ford/ford-design-system/src/web-components/fmc-forms/fmc-count.js';
import '@ford/ford-design-system/src/web-components/fmc-forms/fmc-combo.js';
import './Summary.css';

function VCRsummary() {
  return (

    <div className='details_cntr'>

      <div id='invoiceheader' className='center' >
       VCR
      </div>
      <div id="persnprptbl_cntr">
        <section
          className="fmc-global-attribute-bar fmc-global-attribute-bar--vr-md fmc-px-3 fmc-pb-3-sm fmc-py-3-md fmc-py-3-lg fmc-py-3-xl fds-color__bg--white"
        >
          <section
            className="fmc-global-attribute-bar fmc-global-attribute-bar--vr-md fmc-px-3 fmc-pb-3-sm fmc-py-3-md fmc-py-3-lg fmc-py-3-xl fds-color__bg--white"
          >
            <div className="fds-layout-gri p-0">
                    <div className="fds-layout-grid__inner p-0 ">
                        <div className="fds-layout-grid__cell--span-6">CUSTOMER NAME: </div>
                        <div className="fds-layout-grid__cell--span-6">ACCOUNT NO: </div>
                    </div>
                </div>

               
          </section>

          <section
            className="fmc-global-attribute-bar fmc-global-attribute-bar--vr-md fmc-px-3 fmc-pb-3-sm fmc-py-3-md fmc-py-3-lg fmc-py-3-xl fds-color__bg--white"
          >
             <div className="fds-layout-gri p-0">
                    <div className="fds-layout-grid__inner p-0 ">
                        <div className="fds-layout-grid__cell--span-4">Date of Recovery:<br/>
                        01/01/2005<br/>
                        (MM/DD/YYYY) </div>
                        <div className="fds-layout-grid__cell--span-1">YEAR:<br/>
                        2022
                        </div>
                        <div className="fds-layout-grid__cell--span-1">MAKE:
                        FORD </div>
                        <div className="fds-layout-grid__cell--span-2">MODEL:
                        <br/>
                        FIESTA </div>
                        <div className="fds-layout-grid__cell--span-4">VEHICLE IDENTIFICATION NUMBER:abcede12345123 </div>
                        
                    </div>
                </div>

          </section>
          <section
            className="fmc-global-attribute-bar fmc-global-attribute-bar--vr-md fmc-px-3 fmc-pb-3-sm fmc-py-3-md fmc-py-3-lg fmc-py-3-xl fds-color__bg--white"
          >
             <div className="fds-layout-gri p-0">
                    <div className="fds-layout-grid__inner p-0 ">
                        <div className="fds-layout-grid__cell--span-6">PREPARED BY(NAME OF THE EMPLOYEE): </div>
                        <div className="fds-layout-grid__cell--span-6">DATE PREPARED: </div>
                    </div>
                </div>
          </section>
          <section
            className="fmc-global-attribute-bar fmc-global-attribute-bar--vr-md fmc-px-3 fmc-pb-3-sm fmc-py-3-md fmc-py-3-lg fmc-py-3-xl fds-color__bg--white"
          >
              <div className="fds-layout-gri p-0">
                    <div className="fds-layout-grid__inner p-0 ">
                        <div className="fds-layout-grid__cell--span-2">MILAGE<br/>
                         </div>
                        <div className="fds-layout-grid__cell--span-4">IGNITION KEY NO:<br/>
                        2022
                        </div>
                        <div className="fds-layout-grid__cell--span-2"> COLOR 
                        FORD </div>
                        <div className="fds-layout-grid__cell--span-4">General Condition:
                        <br/>
                        FIESTA </div>
                       
                        
                    </div>
                </div>


          </section>

          <section
            className="fmc-global-attribute-bar fmc-global-attribute-bar--vr-md fmc-px-3 fmc-pb-3-sm fmc-py-3-md fmc-py-3-lg fmc-py-3-xl fds-color__bg--white"
          >
              <div className="fds-layout-gri p-0">
                    <div className="fds-layout-grid__inner p-0 ">
                        <div className="fds-layout-grid__cell--span-2"> ACCESSORIES: </div>
                        <div className="fds-layout-grid__cell--span-4">
                        <div class="fmc-check">
                            <label>
                              <input type="checkbox" name="checkbox-item-1" value="Auto Trans" checked />
                              <span>Auto Trans</span>
                            </label>
                            <label>
                              <input type="checkbox" name="checkbox-item-2" value="A/C" />
                              <span>A/C</span>
                            </label>
                            <label>
                              <input type="checkbox" name="checkbox-item-2" value="Viny Roof" />
                              <span>Viny Roof</span>
                            </label>
                           
                            
                          </div>

                           </div>
                           <div className="fds-layout-grid__cell--span-2">
                              <div class="fmc-check">
                              <label>
                              <input type="checkbox" name="checkbox-item-2" value="P Seats" />
                              <span>P Seats</span>
                            </label>
                                  <label>
                                    <input type="checkbox" name="checkbox-item-1" value="item-1" checked />
                                    <span>P windows</span>
                                  </label>
                                  <label>
                                    <input type="checkbox" name="checkbox-item-2" value="item-2" />
                                    <span>FM Radio</span>
                                  </label>
                                 
                                  
                                </div>

                           </div>
                           <div className="fds-layout-grid__cell--span-2">
                              <div class="fmc-check">
                              <label>
                                    <input type="checkbox" name="checkbox-item-2" value="item-2" />
                                    <span>CD Radio</span>
                                  </label>
                              <label>
                                    <input type="checkbox" name="checkbox-item-2" value="item-2" />
                                    <span>Tilt Cool</span>
                                  </label>
                                  <label>
                                    <input type="checkbox" name="checkbox-item-2" value="item-2" />
                                    <span>Cruise control</span>
                                  </label>
                                  
                                 
                                </div>

                           </div>
                           <div className="fds-layout-grid__cell--span-2">
                              <div class="fmc-check">
                              <label>
                                    <input type="checkbox" name="checkbox-item-1" value="item-1" checked />
                                    <span>Diseal</span>
                                  </label>
                                  
                                </div>

                           </div>
                           
                    </div>
                </div>
          </section>
          
          <section
            className="fmc-global-attribute-bar fmc-global-attribute-bar--vr-md fmc-px-3 fmc-pb-3-sm fmc-py-3-md fmc-py-3-lg fmc-py-3-xl fds-color__bg--white"
          >
              <div className="fds-layout-gri p-0">
                    <div className="fds-layout-grid__inner p-0 ">
                        <div className="fds-layout-grid__cell--span-2">MECHANICAL<br/>
                        Good
                         </div>
                        <div className="fds-layout-grid__cell--span-3">GLASS:<br/>
                       <div> Good </div>
                        <br/>
                        INDICATOR LOCATION:<br/>
                        </div>
                        <div className="fds-layout-grid__cell--span-4"> TIRES : <br/>
                    
                        Left Front:AVERAGE <br/>
                        
                        Left Rear:AVERAGE <br/>
                        Right Front :AVERAGE <br/>
                        Left Rear:GOOD <br/>
                        
                         </div>
                        <div className="fds-layout-grid__cell--span-3">INTERIOR:
                        <br/>
                        Clean 
                        <br/>
                      Explain:

                        </div>
                                               
                    </div>
                </div>


          </section>

          <section
            className="fmc-global-attribute-bar fmc-global-attribute-bar--vr-md fmc-px-3 fmc-pb-3-sm fmc-py-3-md fmc-py-3-lg fmc-py-3-xl fds-color__bg--white"
          >
              <div className="fds-layout-gri p-0">
                    <div className="fds-layout-grid__inner p-0 ">
                        <div className="fds-layout-grid__cell--span-2"> BODY: <br/>
                        BODY: AVERAGE<br/>
                        PAINT: AVERAGE <br/>
                        BUMPER :AVERAGE<br/>
                        
                         </div>
                        <div className="fds-layout-grid__cell--span-3">
                        <div> DENT and RUST LOCATION</div>
                              <div class="fmc-check">
                                <label>
                                <input type="checkbox" name="checkbox-item-2" value="P Seats" />
                                <span> Front Quater Panel</span>
                              </label>
                                  <label>
                                    <input type="checkbox" name="checkbox-item-1" value="item-1" checked />
                                    <span>Front Door</span>
                                  </label>
                                  <label>
                                    <input type="checkbox" name="checkbox-item-2" value="item-2" />
                                    <span>Rear DOOR </span>
                                  </label>
                                  <label>
                                    <input type="checkbox" name="checkbox-item-2" value="P Seats" />
                                    <span> Rear Quarter Panel</span>
                                  </label>
                                  
                                </div>
                           </div>
                           <div className="fds-layout-grid__cell--span-3">
                           <div> PASSENGER SIDE</div>
                              <div class="fmc-check">
                                <label>
                                <input type="checkbox" name="checkbox-item-2" value="P Seats" />
                                <span>Front Quater Panel</span>
                              </label>
                                  <label>
                                    <input type="checkbox" name="checkbox-item-1" value="item-1" checked />
                                    <span>Front Door</span>
                                  </label>
                                  <label>
                                    <input type="checkbox" name="checkbox-item-2" value="item-2" />
                                    <span>Rear  Door </span>
                                  </label>
                                  <label>
                                    <input type="checkbox" name="checkbox-item-2" value="P Seats" />
                                    <span>Rear Quarter Panel</span>
                                  </label>
                                  
                                </div>                          

                           
                           </div>
                           <div className="fds-layout-grid__cell--span-2">
                            <div> TOP</div>
                              <div class="fmc-check">
                              <label>
                                    <input type="checkbox" name="checkbox-item-2" value="item-2" />
                                    <span>Hood</span>
                                  </label>
                              <label>
                                    <input type="checkbox" name="checkbox-item-2" value="item-2" />
                                    <span>Roof </span>
                                  </label>
                                  <label>
                                    <input type="checkbox" name="checkbox-item-2" value="item-2" />
                                    <span>Trunck</span>
                                  </label>
                                  
                                 
                                </div>

                           </div>
                           <div className="fds-layout-grid__cell--span-2">
                            <div>Bumper</div>
                              <div class="fmc-check">
                              <label>
                                    <input type="checkbox" name="checkbox-item-1" value="item-1" checked />
                                    <span>Front</span>
                                  </label>
                                  <label>
                                    <input type="checkbox" name="checkbox-item-1" value="item-1" checked />
                                    <span>Rear</span>
                                  </label>
                                  
                                </div>

                           </div>
                           
                    </div>
                </div>
          </section>
          <section
            className="fmc-global-attribute-bar fmc-global-attribute-bar--vr-md fmc-px-3 fmc-pb-3-sm fmc-py-3-md fmc-py-3-lg fmc-py-3-xl fds-color__bg--white"
          >
            <div className="fds-layout-gri p-0">
                    <div className="fds-layout-grid__inner p-0 ">
                        <div className="fds-layout-grid__cell--span-12">REMARKS MISSING EQUIPMENT: </div>
                        
                    </div>
                </div>

           
          </section>
          <section
            className="fmc-global-attribute-bar fmc-global-attribute-bar--vr-md fmc-px-3 fmc-pb-3-sm fmc-py-3-md fmc-py-3-lg fmc-py-3-xl fds-color__bg--white"
          >
            <div className="fds-layout-gri p-0">
                    <div className="fds-layout-grid__inner p-0 ">
                        <div className="fds-layout-grid__cell--span-12">ESTIMATED INSURANCE DAMAGE AMOUNT: </div>
                        
                    </div>
                </div>
          </section>
          <section
            className="fmc-global-attribute-bar fmc-global-attribute-bar--vr-md fmc-px-3 fmc-pb-3-sm fmc-py-3-md fmc-py-3-lg fmc-py-3-xl fds-color__bg--white"
          >
            <div className="fds-layout-gri p-0">
                    <div className="fds-layout-grid__inner p-0 ">
                        <div className="fds-layout-grid__cell--span-6">VECHICAL RECOVERED FROM <br/>
                        
                        <div><fieldset class="fmc-check fmc-check--horizontal">

                      <label>
                        <input type="radio" name="radio-button-items" value="item-1" checked="" />
                        <span>Customer</span>
                      </label>

                      <label>
                        <input type="radio" name="radio-button-items" value="item-3" disabled="" />
                        <span>Other(SPECIFY)</span>
                      </label>

                    </fieldset>
                    </div>
                                
                        </div>
                        <div className="fds-layout-grid__cell--span-6">RECOVERD FROM :<br/>
                          ADDRESS:ESRNDG <br/>
                          CITY:EIBGDS <br/>
                          STATE:IN <br/>
                          ZIP:12345 <br/>
                         </div>
                    </div>
                </div>

               
          </section>
          <section
            className="fmc-global-attribute-bar fmc-global-attribute-bar--vr-md fmc-px-3 fmc-pb-3-sm fmc-py-3-md fmc-py-3-lg fmc-py-3-xl fds-color__bg--white"
          >
            <div className="fds-layout-gri p-0">
                    <div className="fds-layout-grid__inner p-0 ">
                        <div className="fds-layout-grid__cell--span-6">WAS RELEASE AND SIGNED BY CUSTOMER: 
                        
                        </div>
                        <div className="fds-layout-grid__cell--span-6">
                          <div><fieldset class="fmc-check fmc-check--horizontal">

                          <label>
                            <input type="radio" name="radio-button-items" value="item-1" checked="" />
                            <span>YES</span>
                          </label>

                          <label>
                            <input type="radio" name="radio-button-items" value="item-3" disabled="" />
                            <span>NO</span>
                          </label>

                          </fieldset>
                          </div> </div>
                        
                    </div>
                </div>

               
          </section>
          <section
            className="fmc-global-attribute-bar fmc-global-attribute-bar--vr-md fmc-px-3 fmc-pb-3-sm fmc-py-3-md fmc-py-3-lg fmc-py-3-xl fds-color__bg--white"
          >
            <div className="fds-layout-gri p-0">
                    <div className="fds-layout-grid__inner p-0 ">
                        <div className="fds-layout-grid__cell--span-6">VECHICAL RECOVERED FROM <br/>
                        
                        <div><fieldset class="fmc-check fmc-check--horizontal">

                      <label>
                        <input type="radio" name="radio-button-items" value="item-1" checked="" />
                        <span>No If no explain below</span>
                      </label>

                      <label>
                        <input type="radio" name="radio-button-items" value="item-3" disabled="" />
                        <span>YEs</span>
                      </label>

                    </fieldset>
                    </div>
                                
                        </div>
                        <div className="fds-layout-grid__cell--span-6">AUTHORITIES NOTIFIED :<br/>
                          NAME:ESRNDG <br/>
                          ADDRESS:ESRNDG <br/>
                          CITY:EIBGDS <br/>
                          STATE/PROV:IN <br/>
                          ZIP:12345 <br/>
                         </div>
                    </div>
                </div>

               
          </section>

          <section
            className="fmc-global-attribute-bar fmc-global-attribute-bar--vr-md fmc-px-3 fmc-pb-3-sm fmc-py-3-md fmc-py-3-lg fmc-py-3-xl fds-color__bg--white"
          >
            <div className="fds-layout-gri p-0">
                    <div className="fds-layout-grid__inner p-0 ">
                        <div className="fds-layout-grid__cell--span-12">OTHER COMMENTS </div>
                        
                    </div>
                </div>
          </section>
          <section
            className="fmc-global-attribute-bar fmc-global-attribute-bar--vr-md fmc-px-3 fmc-pb-3-sm fmc-py-3-md fmc-py-3-lg fmc-py-3-xl fds-color__bg--white"
          >
            <div className="fds-layout-gri p-0">
                    <div className="fds-layout-grid__inner p-0 ">
                        <div className="fds-layout-grid__cell--span-6">VECHICAL RECOVERED FROM: <br/>
                        
                        <div><fieldset class="fmc-check fmc-check--vertical">
                          
                      <label>
                        <input type="radio" name="radio-button-items" value="item-1" checked="" />
                        <span>AUCTION</span>
                      </label>

                      <label>
                        <input type="radio" name="radio-button-items" value="item-3" disabled="" />
                        <span>CONTRACTOR</span>
                      </label>
                      <label>
                        <input type="radio" name="radio-button-items" value="item-1" checked="" />
                        <span>DEALER</span>
                      </label>

                      <label>
                        <input type="radio" name="radio-button-items" value="item-3" disabled="" />
                        <span>OTHER</span>
                      </label>

                    </fieldset>
                    </div><br/>
                                
                        </div>
                        <div className="fds-layout-grid__cell--span-6">AUTHORITIES NOTIFIED :<br/>
                          NAME:ESRNDG <br/>
                          ADDRESS:ESRNDG <br/>
                          CITY:EIBGDS <br/>
                          STATE/PROV:IN <br/>
                          PHONE:12345 <br/>
                         </div>
                    </div>
                </div>

               
          </section>


</section>
<div className='para'>
                <ul class="fmc-list" >
                  <li>	Prior to recording the mileage reflected on the vehicle’s odometer consider the age  , appearance  and  condition of the vehicle and  look for indicator of mileage discrepancies such as  oil change stickers ,repair  work receipt, warranty book entries and odometer replacement stickers .Note any mileage discrepancies or inconsistencies in the Comments section.</li>
                  <li>	California Repossession :In accordance  with Section 75005.115 of the Business  and  Professions Code , this condition report is a general assessment of the collateral and does not include all damage or missing parts.</li>
                  
                </ul>
              </div>


      </div>
     
              

    </div>
  );

}

export default VCRsummary;