import "./Header.css";
import {FaRegUser} from "react-icons/fa";
import '@ford/ford-design-system/src/web-components/fmc-button/fmc-button.js';
import {showAlert} from "../../Services/CommonFunctions";
import {useNavigate} from "react-router-dom";

function Header(props) {

    const navigate = useNavigate();
    function logout(){
        sessionStorage.clear()
        showAlert("Logged Out Successfully!!", "success").then(r =>console.log(r))
        navigate("/login")
    }
    function summaryOnclick()
        {
            navigate("/OutstandingAssignment")

        }
    
        function profileOnclick()
        {
            navigate("/Profile")
        }
    return (
        <>
            <div className="fmc-top-banner__demo-page">
                <div className="fmc-top-banner__demo d-flex">
                    <img className="fmc-top-banner__demo-billboard" src={process.env.PUBLIC_URL + '/Assets/Ford.png'}
                         alt=""/>
                    <h4 className="fmc-type--heading5">{props.heading}</h4>

                    {(() => {
                         if (props.heading === "Repo Admin Tool" || props.heading === "Contractor ProfileJs" ||
                            props.heading ==="Add Contractor ProfileJs"||props.heading ==="Update Coverage Area"||
                            props.heading==="Coverage" || props.heading==="Modify Contractor ProfileJs") {
                            return (
                                <div>

                                    <button className="fmc-button" style={{display: `${props.value}`}} onClick={logout}>Logout<span
                                        className="fds-icon fds-icon fds-icon--offset-right fds-font--ford-icons__clear"></span>

                                    </button>

                                </div>
                            )
                        } else {
                            return (
                                <div>
                                    <button className="fmc-button" onClick={summaryOnclick}>Summary
                                        <span
                                            className="fds-icon fds-icon fds-icon--offset-right "></span>
                                    </button>

                                    <button className="fmc-button"  onClick={profileOnclick}>
                                        <div className="icon_text"><FaRegUser/> Profile</div>
                                        <span
                                            className="fds-icon fds-icon fds-icon--offset-right "></span>
                                    </button>
                                {/* help button removed from all the pages */}
                                    {/* <button className="fmc-button">Help<span
                                        className="fds-icon fds-icon fds-icon--offset-right fds-font--ford-icons__info-filled"></span>
                                    </button> */}


                                    <button className="fmc-button" style={{display: `${props.value}`}}>Logout<span
                                        className="fds-icon fds-icon fds-icon--offset-right fds-font--ford-icons__clear"></span>

                                    </button>


                                </div>
                            )
                        }
                    })()}
                    {/*<div>*/}
                    {/*    <button className="fmc-button">Summary*/}
                    {/*        <span*/}
                    {/*            className="fds-icon fds-icon fds-icon--offset-right "></span>*/}
                    {/*    </button>*/}

                    {/*    <button className="fmc-button">*/}
                    {/*        <div className="icon_text"><FaRegUser/> ProfileJs</div>*/}
                    {/*        <span*/}
                    {/*            className="fds-icon fds-icon fds-icon--offset-right "></span>*/}
                    {/*    </button>*/}
                    {/*    <button className="fmc-button">Help<span*/}
                    {/*        className="fds-icon fds-icon fds-icon--offset-right fds-font--ford-icons__info-filled"></span>*/}
                    {/*    </button>*/}


                    {/*    <button className="fmc-button" style={{display: `${props.value}`}}>Logout<span*/}
                    {/*        className="fds-icon fds-icon fds-icon--offset-right fds-font--ford-icons__clear"></span>*/}

                    {/*    </button>*/}


                    {/*</div>*/}

            

                </div>
            </div>
        </>
    );
}

export default Header;