import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";

function Unauthorized(){
  return(<>
    <Header heading="Unauthorized User" summaryDisplay="none" profileDisplay="none" helpDisplay="none"/>
    <p style={{
      textAlign: 'center', 
      lineHeight: '100px', 
      height: '100px'
    }}>
      <span style={{
        display: 'inline-block',
        verticalAlign: 'middle',
        lineHeight: 'normal'
      }}>You are not an authorized RepoWeb user.</span>
    </p>
    <Footer/>
  </>);
}

export default Unauthorized;