import React, {useEffect, useState} from 'react';
import Header from "../../Components/Header/Header";
import InvoicePage from '../invoice/InvoicePage';
import AssignmentDetails from "../AssignmentDetails/AssignmentDetails";
import VehicleConditionReport from "../VehicleConditionReport/VehicleConditionReport";
import PersonalProperty from "../PersonalProperty/PersonalProperty";
import {useParams} from "react-router-dom";
import {getCall} from "../../Services/CommonService";
import {getUserInfoFromSession} from "../../Services/session.mgmt";
import './Maintab.css';

function Maintab() {
    let { teamWrkBpiNb } = useParams();
    const userInfo = getUserInfoFromSession();
    const [assignmentStatus, setAssignmentStatus] = useState("");
    const [activeTab, setActiveTab] = useState(1);  // State to track the active tab

    const repoAssignmentStatus = () => {
        const repoAssignmentStatusInfo = `/DataMgr/getRepoAssignmentStatus/${teamWrkBpiNb}/${userInfo.cntrctrNb}`;
        getCall(repoAssignmentStatusInfo)
            .then((response) => {
                if (response.status === 200) {
                    const cntrctrActnCd = response.data.result.repoAssignmentStatus[0].cntrctrActnCd;
                    const csrActnCd = response.data.result.repoAssignmentStatus[0].csrActnCd;
                    if (cntrctrActnCd === 'H' || csrActnCd === 'H') {
                        setAssignmentStatus("H");
                    }
                    if (cntrctrActnCd === 'G' || csrActnCd === 'G') {
                        setAssignmentStatus("G");
                    }
                    if (cntrctrActnCd === 'A' || csrActnCd === 'A') {
                        setAssignmentStatus("A");
                    }
                } else {
                    throw new Error("Something went wrong with the API server!");
                }
            })
            .catch((error) => console.error(error));
    };

    useEffect(() => {
        repoAssignmentStatus();
    }, []);

    // Function to handle switching to the next tab
    const handleContinue = () => {
        setActiveTab((prevTab) => (prevTab < 4 ? prevTab + 1 : prevTab));  // Go to next tab if less than 4
    };

    return (
        <div>
            <Header heading="" />
            <div className="tabs">
                <h3 id="tablist-1"></h3>
                <div role="tablist" aria-labelledby="tablist-1" className="manual" style={{textAlign:"center"}}>
                    <button
                        id="tab-1"
                        type="button"
                        role="tab"
                        aria-selected={activeTab === 1}
                        aria-controls="tabpanel-1"
                        onClick={() => setActiveTab(1)}
                    >
                        <span className="focus">Assignment Details</span>
                    </button>
                    <button
                        id="tab-2"
                        type="button"
                        role="tab"
                        aria-selected={activeTab === 2}
                        aria-controls="tabpanel-2"
                        onClick={() => setActiveTab(2)}
                    >
                        <span className="focus">VCR</span>
                    </button>
                    <button
                        id="tab-3"
                        type="button"
                        role="tab"
                        aria-selected={activeTab === 3}
                        aria-controls="tabpanel-3"
                        onClick={() => setActiveTab(3)}
                    >
                        <span className="focus">Invoice</span>
                    </button>
                    <button
                        id="tab-4"
                        type="button"
                        role="tab"
                        aria-selected={activeTab === 4}
                        aria-controls="tabpanel-4"
                        onClick={() => setActiveTab(4)}
                    >
                        <span className="focus">Personal Property</span>
                    </button>
                </div>

                {/* Tab Panels */}
                <div id="tabpanel-1" role="tabpanel" aria-labelledby="tab-1" className={activeTab === 1 ? "" : "is-hidden"}>
                    <AssignmentDetails bpiNo={teamWrkBpiNb} onContinue={handleContinue}/>
                </div>
                <div id="tabpanel-2" role="tabpanel" aria-labelledby="tab-2" className={activeTab === 2 ? "" : "is-hidden"}>
                    <VehicleConditionReport bpiNo={teamWrkBpiNb} onContinue={handleContinue} status = {assignmentStatus}/>
                </div>
                <div id="tabpanel-3" role="tabpanel" aria-labelledby="tab-3" className={activeTab === 3 ? "" : "is-hidden"}>
                    <InvoicePage bpiNo={teamWrkBpiNb} onContinue={handleContinue} status = {assignmentStatus}/>
                </div>
                <div id="tabpanel-4" role="tabpanel" aria-labelledby="tab-4" className={activeTab === 4 ? "" : "is-hidden"}>
                    <PersonalProperty bpiNo={teamWrkBpiNb} onContinue={handleContinue} status = {assignmentStatus}/>
                </div>

            </div>
            <div className="fmc-bottom-banner__content center copy-rightbanner">
                Copyright &copy; 2024 Ford Motor Credit Company. All Rights Reserved
            </div>
        </div>
    );
}

export default Maintab;