import {
    getAssignmentInfo,
    getBuyerCobuyerInfo,
    getPersonalPropertyFUP,
    getSummary,
    getVechileDsiposalInfo
} from "../api/assignment.api";

export const AssignmentService = {

    getAssignmentInfo: async (teamWrkBpiNb, cntrctrNb) => {
        return getAssignmentInfo(teamWrkBpiNb,cntrctrNb);
    },
    getPersonalPropertyFUP: async (cntrctrNb) => {
        return getPersonalPropertyFUP(cntrctrNb);
    },
    getSummary: async (cntrctrNb) => {
        return getSummary(cntrctrNb);
    },
    getBuyerCobuyerInfo: async (vinNb) => {
        return getBuyerCobuyerInfo(vinNb);
    },
    getVechileDsiposalInfo :async () => {
        return getVechileDsiposalInfo();
    }
}