import Header from "../../Components/Header/Header";
import React, {useEffect, useState} from "react";
import "./CancelAssignment.css"
import {getCall, postCallBPM, postCallPinnacle, putCall} from "../../Services/CommonService";
import {useParams} from "react-router-dom";
import AssignmentDetailsHeader from "../common/AssignmentDetailsHeader";
import {getUserInfoFromSession} from "../../Services/session.mgmt";

function CancelAssignment(props){

    let { teamWrkBpiNb } = useParams();
    console.log(teamWrkBpiNb)

    const [assignmentDetails, setAssignmentDetails] = useState([])
    const [buyerCoBuyerInfo, setBuyerCoBuyerInfo] = useState([])
    const userInfo = getUserInfoFromSession()
    const getAssignmentInfo ='manualAssignment/AssignmentInfo/'+ teamWrkBpiNb+"/" + userInfo.cntrctrNb;

    // 1)Update ZBPT150_CNTRCTACCT table
    const onclickHandler=()=>{
        putCall(
            "processQuestion/updateCntrctAcct?bpiNb="+teamWrkBpiNb+"&cntrctrNb="+userInfo.cntrctrNb+"&sConfirm=Y&sCsrAction=R").then((response) => console.log(response));
        // 2) BPM web service call
        postCallBPM({"custAccNb":"1234567","teamwrkBpiNb":teamWrkBpiNb,"coBusCd":"1","userId":userInfo.userid,"action":"C"}).then(response =>console.log(response))
        //3) Customer Activity Service call for logCompassContactForWF2
        postCallPinnacle({
            systemName: "string",
            enteredBranchCode: "st",
            customerServiceFollowUpDate: "2021-10-15",
            customerServiceFollowUpTime: "11:25 AM",
            comment: "Anbu test",
            isPermanent: true,
            thirdPartySequenceNumber: 9999,
            contactThirdPartySequenceNumber: 9999,
            createdBy: "string",
            createdTimeStamp: "2018-05-23 11:25:12",
            typeCode: 8,
            actionCode: 1,
            partyCode: 5,
            resultsCode: 1,
            reasonCode: 1,
            rootCause: 1,
            categoryCode: 1,
            receivableAccountNumber: 2147483647,
            coBusCd: 9999,
            promiseToPayDate1: "2022-03-28",
            promiseToPayAmount1: 9223,
            promiseToPayDate2: "2022-03-28",
            promiseToPayAmount2: 92233
        }).then((response) => console.log(response));
    }
    useEffect(() => {

        getCall(getAssignmentInfo).then(response => setAssignmentDetails(response.data));
        // getCall(getBuyerInfo).then(response =>{
        // if(response.message === ""){
        //     console.log(response.data)
        //     setBuyerCoBuyerInfo(response.data)
        // }else{
        setBuyerCoBuyerInfo([
            {
                "customerAccount": {
                    "account": {
                        "receivableAccountNumber": 54957040,
                        "companyBusinessCode": 1,
                        "contractType": "Ret",
                        "accountStatus": "ACTIVE ACCOUNT",
                        "vehicleYear": "2016",
                        "vehicleMake": "FORD",
                        "vehicleModel": "F150",
                        "vehicleIdentificationNumber": "1FTEW1EPXGKE25305"
                    },
                    "applicant": {
                        "applicantCode": "PR",
                        "firstName1": "MELANIE",
                        "firstName2": "J",
                        "firstName3": null,
                        "lastName1": "MEZA",
                        "lastName2": null,
                        "title": null,
                        "generation": null,
                        "businessName": null,
                        "address": {
                            "addressLine1": "5408 OLIVINE CT",
                            "addressLine2": null,
                            "city": "LAS VEGAS",
                            "stateCode": "NV",
                            "otherCountry": null,
                            "otherPostCode": null,
                            "otherState": null,
                            "postCode": "89130-1990",
                            "country": "USA"
                        }
                    }
                }
            }
        ])
        //}
        //});

    }, []);
    return(
        <>
            <Header heading="Cancel Assignment" />
            <br/>
            <AssignmentDetailsHeader bpiNo = {teamWrkBpiNb}/>
            <br/>
            <div>
                {buyerCoBuyerInfo.map((buyerCoBuyerInfo)=> {
                    let applicant = buyerCoBuyerInfo.customerAccount.applicant
                    return (
                        <section key={applicant.firstName1+'-'+applicant.firstName2+'-'+applicant.firstName3}
                            className="fmc-global-attribute-bar fmc-global-attribute-bar--vr-md fmc-px-3 fmc-pb-3-sm fmc-py-3-md fmc-py-3-lg fmc-py-3-xl fds-color__bg--white"
                        >
                            <div className="fds-weight--bold">Buyer Information:</div>
                            <div className="fds-layout-gri p-0">
                                <div className="fds-layout-grid__inner p-0 ">
                                    <div className="fds-layout-grid__cell--span-12">Name: {applicant.firstName1} {applicant.firstName2} {applicant.firstName3}</div>
                                </div>
                            </div>
                            <div className="fds-layout-gri p-0">
                                <div className="fds-layout-grid__inner p-0 ">
                                    <div className="fds-layout-grid__cell--span-6">Address: {applicant.address.addressLine1} {applicant.address.addressLine2} {applicant.address.city} {applicant.address.stateCode} {applicant.address.otherCountry} {applicant.address.otherPostCode} {applicant.address.otherState} {applicant.address.postCode} {applicant.address.country}</div>
                                    <div className="fds-layout-grid__cell--span-6">Employer: </div>
                                </div>
                            </div>
                            <div className="fds-layout-gri p-0">
                                <div className="fds-layout-grid__inner p-0 ">
                                    <div className="fds-layout-grid__cell--span-6">Phone: </div>
                                    <div className="fds-layout-grid__cell--span-6">Phone: </div>
                                </div>
                            </div>
                        </section>
                    );

                })}
                {buyerCoBuyerInfo.map((buyerCoBuyerInfo)=> {
                    if(buyerCoBuyerInfo.customerAccount.applicant.applicantCode === "PR"){
                        return null
                    }else{
                        let applicant = buyerCoBuyerInfo.customerAccount.applicant
                        return (
                            <section key={applicant.firstName1+'-'+applicant.firstName2+'-'+applicant.firstName3}
                                className="fmc-global-attribute-bar fmc-global-attribute-bar--vr-md fmc-px-3 fmc-pb-3-sm fmc-py-3-md fmc-py-3-lg fmc-py-3-xl fds-color__bg--white"
                            >
                                <div className="fds-weight--bold">Co-Buyer Information:</div>
                                <div className="fds-layout-gri p-0">
                                    <div className="fds-layout-grid__inner p-0 ">
                                        <div className="fds-layout-grid__cell--span-12">Name: {applicant.firstName1} {applicant.firstName2} {applicant.firstName3}</div>
                                    </div>
                                </div>
                                <div className="fds-layout-gri p-0">
                                    <div className="fds-layout-grid__inner p-0 ">
                                        <div className="fds-layout-grid__cell--span-6">Address: {applicant.address.addressLine1} {applicant.address.addressLine2} {applicant.address.city} {applicant.address.stateCode} {applicant.address.otherCountry} {applicant.address.otherPostCode} {applicant.address.otherState} {applicant.address.postCode} {applicant.address.country}</div>
                                        <div className="fds-layout-grid__cell--span-6">Employer: </div>
                                    </div>
                                </div>
                                <div className="fds-layout-gri p-0">
                                    <div className="fds-layout-grid__inner p-0 ">
                                        <div className="fds-layout-grid__cell--span-6">Phone: </div>
                                        <div className="fds-layout-grid__cell--span-6">Phone: </div>
                                    </div>
                                </div>
                            </section>
                        );
                    }

                })}
                {assignmentDetails.map((assignmentDetails) => {
                    return (
                        <section key={assignmentDetails.recvrMthdCdDs}
                            className="fmc-global-attribute-bar fmc-global-attribute-bar--vr-md fmc-px-3 fmc-pb-3-sm fmc-py-3-md fmc-py-3-lg fmc-py-3-xl fds-color__bg--white"
                        >
                            <div className="fds-weight--bold">Assignment Information:</div>
                            <div className="fds-layout-gri p-0">
                                <div className="fds-layout-grid__inner p-0 ">
                                    <div className="fds-layout-grid__cell--span-6">Type of surrender: {assignmentDetails.recvrMthdCdDs}</div>
                                    <div className="fds-layout-grid__cell--span-6">Fee: {assignmentDetails.acptCntrctrFeeAm}</div>
                                </div>
                            </div>
                            <div className="fds-layout-gri p-0">
                                <div className="fds-layout-grid__inner p-0 ">
                                    <div className="fds-layout-grid__cell--span-6">CSR Name: </div>
                                    <div className="fds-layout-grid__cell--span-6">Phone: </div>
                                </div>
                            </div>
                            <div className="fds-layout-gri p-0">
                                <div className="fds-layout-grid__inner p-0 ">
                                    <div className="fds-layout-grid__cell--span-6">Special Instructions /
                                        Directions: </div>
                                </div>
                            </div>
                        </section>
                    );

                })}
                <br/>
                <section>
                    <div className="center">
                        <button type="button" className="fmc-button" onClick={onclickHandler}>Acknowledge</button>
                    </div>
                </section>
            </div>

        </>

    )
}

export default CancelAssignment;