import Header from "../../Components/Header/Header";
// required
import '@ford/ford-design-system/src/web-components/fmc-forms/fmc-control.js';

// choose a input component
import '@ford/ford-design-system/src/web-components/fmc-forms/fmc-input.js';
import '@ford/ford-design-system/src/web-components/fmc-forms/fmc-select.js';
import '@ford/ford-design-system/src/web-components/fmc-forms/fmc-textarea.js';

// optional sub components
import '@ford/ford-design-system/src/web-components/fmc-forms/fmc-count.js';
import '@ford/ford-design-system/src/web-components/fmc-forms/fmc-combo.js';
import React, {useEffect, useState} from 'react';
import PersonalProprtySum from './PersonalProprtySum';
import Invoicesummary from "./Invoicesummary";
import VCRsummary from "./VCRsummary";
import {PINNCALE_LOG_OBJ, VEHICLE_DISPOSAL_OBJ} from "../../constants/commonJsonConstants";
import {
    CommonDataService,
    convertDateToISOString,
    getcommentStr,
    getCurrentTimestamp,
    getCurrentTimestampInISOFormat
} from "../../Services/CommonDataService";

import * as Constants from "../../constants/RepoReactConstants";
import {getUserInfoFromSession} from "../../Services/session.mgmt";
import {AccountSummaryService} from "../../Services/AssignmentSummaryService";

function AssignmentSummary() {

  const [accountInformation, setAccountInformation] = useState([]);
  const [commonRepoInformation, setCommonRepoInformation] = useState([]);
  const currntenv = "DEV";
  const [contractorInfoForComment, setContractorInfoForComment] = useState([]);
  const userInfo = getUserInfoFromSession()
  const [vcrObject, setVcrObject] = useState({});


  // Insert or update YWFS350_VEHDISPOFU  - VehicleDisposal Followup

  const insertOrUpdateYWFS350VEHDISPOFU = () => {


    console.log("vcrresponse:::::::::::" + vcrObject.teamWrkBpiNb);

    console.log(convertDateToISOString(vcrObject.rcvrDt)); // Output
    console.log("current timestamp::::", getCurrentTimestampInISOFormat());

    VEHICLE_DISPOSAL_OBJ.custAcctNb = accountInformation.custAcctNb;
    VEHICLE_DISPOSAL_OBJ.coBusCd = accountInformation.coBusCd;
    VEHICLE_DISPOSAL_OBJ.discorigBranch = accountInformation.discorigBranchCd;
    VEHICLE_DISPOSAL_OBJ.teamworkBPI = accountInformation.teamwrkBpiNb;
    VEHICLE_DISPOSAL_OBJ.vin = commonRepoInformation.vinSerialNb;
    VEHICLE_DISPOSAL_OBJ.repoTrmDt = convertDateToISOString(vcrObject.rcvrDt);
    VEHICLE_DISPOSAL_OBJ.mlDrvnNb = vcrObject.vehMlgeNb;
    VEHICLE_DISPOSAL_OBJ.dispoRsnCd = 1;
    VEHICLE_DISPOSAL_OBJ.crtDt = getCurrentTimestampInISOFormat();
    VEHICLE_DISPOSAL_OBJ.legalSaleDt = null;
    VEHICLE_DISPOSAL_OBJ.lgclDelIn = null;

    VEHICLE_DISPOSAL_OBJ.availPkDt = convertDateToISOString(vcrObject.rcvrDt);
    VEHICLE_DISPOSAL_OBJ.lastUpdatedUserIdentificationCode = userInfo.userid;
    VEHICLE_DISPOSAL_OBJ.lastUpdatedTimestamp = getCurrentTimestampInISOFormat();

    console.log("VEHICLE_DISPOSAL_OBJ::" +VEHICLE_DISPOSAL_OBJ);
  
    let cobusCode = accountInformation.coBusCd;
    let origBranch = accountInformation.discorigBranchCd;
    let customerAccoutNumber = accountInformation.custAcctNb;

    AccountSummaryService.getVechileDisposalInfo(customerAccoutNumber, cobusCode, origBranch).then(r => {
      if (r.data.custAcctNb == undefined) {
        console.log(" respone" + r.status);
        console.log("Insert vehicleDsiposalinfo");

        AccountSummaryService.CreateVehicleDisposalInfo(VEHICLE_DISPOSAL_OBJ).then(r => console.log("insert call  response" + r.status));

      }
      else {
        console.log("Update vehicleDsiposalinfo");
        AccountSummaryService.UpdateVehicleDisposalInfo(customerAccoutNumber, cobusCode, origBranch, VEHICLE_DISPOSAL_OBJ).then(r => console.log(r.status));

      }
    });

  }


  /*=============================== log pinnale for  Ground event  ====================*/
  const logPinnacle = () => {

    PINNCALE_LOG_OBJ.receivableAccountNumber = accountInformation.custAcctNb;
    PINNCALE_LOG_OBJ.coBusCd = accountInformation.coBusCd;
    PINNCALE_LOG_OBJ.createdTimeStamp = getCurrentTimestamp();
    PINNCALE_LOG_OBJ.enteredBranchCode = accountInformation.superBranchCd;
    console.log(" PINNCALE  LOG Object to Log before add comment :::::::", PINNCALE_LOG_OBJ);

    console.log("comment String with pinnalce:::::::::::::::::::", getcommentStr(contractorInfoForComment, Constants.ACTION_GROUNDING, Constants.rsGROUNDED, userInfo.userid));
    PINNCALE_LOG_OBJ.comment = getcommentStr(contractorInfoForComment, Constants.ACTION_GROUNDING, Constants.rsGROUNDED, userInfo.userid);
    console.log(" after comment set:::::::::::::::::::", PINNCALE_LOG_OBJ);

    if (currntenv != 'DEV') {
      CommonDataService.createPinnacleContact(PINNCALE_LOG_OBJ).then(r => {
       if (r.status == 204) {
          console.log("pinnacle Response " + "no data");
        }
      });

    }
    else {
      console.log("Pinncale Log Object in DEV " + JSON.stringify(PINNCALE_LOG_OBJ));
    }

  }

  const onsummarySubmit = () => {

    logPinnacle();
    insertOrUpdateYWFS350VEHDISPOFU();
    

  }

  useEffect(() => {



    const fetchcommonInfo = async () => {

      try {
        // To retrieve   account information and commonRepo Information and  contractorcontact information
        CommonDataService.getAccountInfo(8259, 31372).then(r => setAccountInformation(r.data));
        CommonDataService.getRepoCommonInfo(8259, 31372).then(r => setCommonRepoInformation(r.data));
        CommonDataService.getRepoCommonInfo(8259, 31372).then(r => setContractorInfoForComment(r.data));
        AccountSummaryService.getVCRDetails(8259).then(r => setVcrObject(r.data));


      } catch (error) {
        // Handle any errors that occur in any of the promises
        console.error('An error occurred:', error);
      }
    }

    fetchcommonInfo();

  }, []);


  return (


    <div >

      <Header heading="" />
      <div className="center">  <h2>Outstanding Assignments Summary</h2> </div>

      <section
        className="fmc-global-attribute-bar fmc-global-attribute-bar--vr-md fmc-px-3 fmc-pb-3-sm fmc-py-3-md fmc-py-3-lg fmc-py-3-xl fds-color__bg--white"
      >
        <p></p>
        <div className="fds-layout-gri p-0">
          <div className="fds-layout-grid__inner p-0 ">
            <div className="fds-layout-grid__cell--span-4"></div>
            <div className="fds-layout-grid__cell--span-4"></div>
            <div className="fds-layout-grid__cell--span-4"></div>
          </div>
        </div>

      </section>
      <br />
      <VCRsummary />
      <PersonalProprtySum />
      <Invoicesummary />
      <div className="center" style={{ padding: '10px' }}>
        <fmc-button label="Submit" onClick={onsummarySubmit}></fmc-button>
      </div>

      <div className="fmc-bottom-banner__content center copy-rightbanner">Copyright &copy; 2024 Ford Motor Credit Company. All Rights Reserved</div>
    </div>

  );

}

export default AssignmentSummary;