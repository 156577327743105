import "./VehicleConditionReport.css"
import React, {useEffect, useState} from 'react';
import {validatevcr} from "./VCRFormValidation";
import {showAlert, showConfirm} from "../../Services/CommonFunctions";
import AssignmentDetailsHeader from "../common/AssignmentDetailsHeader";
import {VCRService} from "../../Services/VCRService";
import {AssignmentService} from "../../Services/AssignmentService";
import {getUserInfoFromSession} from "../../Services/session.mgmt";
import {getAuctionLocationList} from "../../api/assignmentsummary.api";

function VehicleConditionReport(props){

    const userInfo = getUserInfoFromSession();
    let teamWrkBpiNb =Number(props.bpiNo);
    let countryCode='USA';
   console.log(props.status)
    console.log(teamWrkBpiNb)
    const VinNumber = "1FTFW1E51MFC15439"
    const [buyerCoBuyerInfo, setBuyerCoBuyerInfo] = useState([])
    const [TireConditionOptions , setTireConditionOptions] = useState([])
    const [OverallConditionOptions , setOverallConditionOptions] = useState([])
    const [GeneralConditionOptions , setGeneralConditionOptions] = useState([])
    const [InteriorConditionOptions , setInteriorConditionOptions] = useState([])
    const [GlassConditionOptions , setGlassConditionOptions] = useState({})
    const [MechanicalConditionOptions , setMechanicalConditionOptions] = useState([])
    const [auctionLocationOptions,setAuctionLocationOptions] = useState([])
    const [VcrFromData , setVcrFormData] = useState({})
    const [VehLocData , setVehLocData] = useState({})
    const [count , setCount] = useState(0)
    const[StateOrProvince , setStateOrProvince] = useState([])
    const [localError, setLocalError] = useState('')

    useEffect(() => {
        const getDropDownData = async () => {
            try {
                const [
                    tireConditionResponse,
                    overallConditionResponse,
                    generalConditionResponse,
                    interiorConditionResponse,
                    glassConditionResponse,
                    mechanicalConditionResponse,
                    stateOrProviceResponse,
                    auctionLocResponse
                ] = await Promise.all([
                    VCRService.getTireCondition(),
                    VCRService.getOverallCondition(),
                    VCRService.getGeneralCondition(),
                    VCRService.getInteriorCondition(),
                    VCRService.getGlassCondition(),
                    VCRService.getMechanicalCondition(),
                    VCRService.getStateOrProvinceByStAbbr('USA'),
                    getAuctionLocationList(countryCode),

                ])

                setTireConditionOptions(tireConditionResponse.data);
                setOverallConditionOptions(overallConditionResponse.data);
                setGeneralConditionOptions(generalConditionResponse.data);
                setInteriorConditionOptions(interiorConditionResponse.data);
                setGlassConditionOptions(glassConditionResponse.data);
                setMechanicalConditionOptions(mechanicalConditionResponse.data);
                setStateOrProvince(stateOrProviceResponse);
                setAuctionLocationOptions(auctionLocResponse.data);

            } catch (err) {
                setLocalError(`Error: ${err.message}`);
            }
        };
        const getFormObj = async ()=>{
            try{
                const [VcrFormResponse
                    , VehlocFormResponse ] =  await Promise.all([
                        VCRService.getVcrObj(),
                        VCRService.getVehLocObj()
                    ])
                const vcrData = VcrFormResponse.data;
                const vehLocData = VehlocFormResponse.data;

              let  modifiedVcrRes = {
                    ...vcrData,
                    teamWrkBpiNb: teamWrkBpiNb,
                  lstUpdtUserId: userInfo.userid
                };
                let  modifiedVehLocRes = {
                    ...vehLocData,
                    teamWrkBpiNb: teamWrkBpiNb,
                    lstUpdtUserId: userInfo.userid
                };
                console.log(modifiedVcrRes)
                console.log(modifiedVehLocRes)
                setVcrFormData(modifiedVcrRes)
                setVehLocData(modifiedVehLocRes)
            }catch(err){
                setLocalError(`Error fetching form object: ${err}`);
            }
        }
        const getBuyerCobuyerInfo = async () =>{
            try{
                const assignmentResponse = await AssignmentService.getBuyerCobuyerInfo(VinNumber);
                console.log( "getBuyerCobuyerInfo.reponse: ==== "+ JSON.stringify(assignmentResponse.data));
                setBuyerCoBuyerInfo(assignmentResponse.data);

                // setBuyerCoBuyerInfo([{
                //     "customerAccount": {
                //         "account": {
                //             "receivableAccountNumber": 60436843,
                //             "companyBusinessCode": 1,
                //             "contractType": "Lse",
                //             "accountStatus": "ACTIVE ACCOUNT",
                //             "vehicleYear": "2021",
                //             "vehicleMake": "FORD",
                //             "vehicleModel": "F150",
                //             "vehicleIdentificationNumber": "1FTFW1E51MFC15439"
                //         },
                //         "applicant": {
                //             "applicantCode": "PR",
                //             "firstName1": "JAMES",
                //             "firstName2": "R",
                //             "firstName3": null,
                //             "lastName1": "BAKER",
                //             "lastName2": null,
                //             "title": null,
                //             "generation": null,
                //             "businessName": null,
                //             "address": {
                //                 "addressLine1": "7 EAGLE CAMP RD",
                //                 "addressLine2": null,
                //                 "city": "SOUTH HERO",
                //                 "stateCode": "VT",
                //                 "otherCountry": null,
                //                 "otherPostCode": null,
                //                 "otherState": null,
                //                 "postCode": "05486-4403",
                //                 "country": "USA"
                //             }
                //         }
                //     }
                // }])
            }catch(err){
                setLocalError(`Error: ${err.message}`);
            }
        }
        getDropDownData();
        getFormObj();
        getBuyerCobuyerInfo();


    }, [count]);



    const clearFormData = async ()=>{
        try{
            const [VcrFormReponse
                , VehlocFormResponse ] =  await Promise.all([
                VCRService.getVcrObj(),
                VCRService.getVehLocObj()
            ])
            setVcrFormData(VcrFormReponse.data)
            setVehLocData(VehlocFormResponse.data)
        }catch(err){
            setLocalError(`Error fetching form object: ${err}`);
        }
    }
    const reset =async () => {
        if (await showConfirm("All data will be cleared, proceed?")) {
            clearFormData();
        } else {
            return null
             }
    }

    const convertValidVcrData = (VcrFromData)=>{
        if(VcrFromData.vehRcvrdFromNm === "OTHER"){
            setVcrFormData(()=>({
                ...VcrFromData,
                ['vehRcvrdFromNm']: VcrFromData.vehRcvOthrSpcfy
            }))
        }
        delete  VcrFromData.vehRcvOthrSpcfy
        return VcrFromData
    }


    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log(VehLocData)
        let errormsg = validatevcr(VcrFromData , VehLocData)
        if(errormsg !== null){
            await showAlert( errormsg, "warning")
        }else {
            let validVcrData = convertValidVcrData(VcrFromData)
            console.log(validVcrData);
            if(saveVcr(validVcrData)){
                props.onContinue()
            }
        }
    }
function saveVcr(vcrData){
       try{
           console.log(vcrData)
           const response = VCRService.saveVcr(vcrData)
            console.log("VCR Information Saved Successfully!!" + response)
                showAlert("VCR Information Saved Successfully!!", "success")
                setCount(count+1)
           return true
        }catch(err)  {
            console.error(err)
            showAlert(err.message, "error");

        }
    }
    const onChangeHandler =(event)=>{
        setVcrFormData(()=>({
            ...VcrFromData,
            [event.target.name]:event.target.value
        }))
    }
    const onChangeHandlerVehLoc =(event)=>{
        setVehLocData(()=>({
            ...VehLocData,
            [event.target.name]:event.target.value
        }))
    }
    const onChangeHandlerInt =(event)=>{
        setVcrFormData(()=>({
            ...VcrFromData,
            [event.target.name]: Number(event.target.value)
        }))
    }

    return(
        <>
            <div>
                {localError ? (
                    <div className="error-message" style={{color: 'red'}}>
                        {localError}
                    </div>
                ) : (
                    <div className="data-content">
                        <br/>
                        <AssignmentDetailsHeader bpiNo={teamWrkBpiNb}/>
                        <div style={{textAlign: "center", padding: 2 + 'em'}}>
                            VEHICLE CONDITION REPORT
                            <br/>
                            Vehicle Disposal Repossession Analysis
                        </div>
                        {/*-----form design started----*/}
                        {/*---Customer details----*/}
                        <form onSubmit={handleSubmit}>
                            <fieldset disabled={props.status === 'H' ? true : false}>
                                <div className="f-0">
                                    <div className="fds-layout-grid p-0">
                                        {buyerCoBuyerInfo.map((buyerCoBuyerInfo)=> {
                                            let customerInfo = buyerCoBuyerInfo.customerAccount
                                            return (
                                        <div className="fds-layout-grid__inner p-0 " key={customerInfo.applicant.firstName1 +'-'+customerInfo.applicant.firstName2 +'-'+customerInfo.applicant.firstName3}>
                                            <div className="fds-layout-grid__cell--span-6">CUSTOMER
                                                NAME: {customerInfo.applicant.firstName1} {customerInfo.applicant.firstName2} {customerInfo.applicant.firstName3}</div>
                                            <div className="fds-layout-grid__cell--span-6">ACCOUNT
                                                NO: {customerInfo.account.receivableAccountNumber}</div>
                                        </div>
                                            );

                                        })}
                                    </div>
                                    <hr/>
                                    {/*------Date of recovery info----*/}
                                    <div style={{textAlign: "center"}}>
                                        <div className="fds-layout-grid p-0">
                                            <div className="fds-layout-grid__inner p-0 ">
                                                <div className="fds-layout-grid__cell--span-4">
                                                    <label>DATE OF RECOVERY: </label>
                                                    <input type="date" placeholder="Lorem Ipsum" className="s-1"
                                                           name="rcvrDt"
                                                           value={VcrFromData.rcvrDt}
                                                           onChange={onChangeHandler}/>
                                                </div>
                                                <div className=" row fds-layout-grid__cell--span-4">
                                                    <div className="column form-group">
                                                        <label>Year</label>
                                                        1234
                                                    </div>
                                                    <div className="column form-group">
                                                        <label>MAKE</label>
                                                        LINC
                                                    </div>
                                                    <div className="column form-group">
                                                        <label>MODEL</label>
                                                        MXX
                                                    </div>
                                                </div>
                                                <div className=" fds-layout-grid__cell--span-4">
                                                    <div className="form-group">
                                                        <label>Vehicle Identification Number</label>
                                                        {VinNumber}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    {/*------PREPARED BY info-----*/}
                                    <div>
                                        <div className="fds-layout-grid p-0">
                                            <div className="fds-layout-grid__inner p-0 ">
                                                <div className="fds-layout-grid__cell--span-6">
                                                    <label>PREPARED BY:(NAME OF EMPLOYEE) </label>
                                                    {/*<Input placeholder="" className="i-0" value ={VcrFromData.prepByTx} onChange={onChangeHandler}/>*/}
                                                    <input type="text" placeholder="" name="prepByTx"
                                                           value={VcrFromData.prepByTx}
                                                           onChange={onChangeHandler}/>
                                                </div>
                                                <div className="fds-layout-grid__cell--span-6">
                                                    <label>DATE PREPARED: </label>
                                                    <input type="date" placeholder="Lorem Ipsum" className="s-1"
                                                           name="prepDt"
                                                           value={VcrFromData.prepDt}
                                                           onChange={onChangeHandler}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    {/*----General Condition-----*/}
                                    <div>
                                        <div className="fds-layout-grid p-0">
                                            <div className="fds-layout-grid__inner p-0 ">
                                                <div className="fds-layout-grid__cell--span-3">
                                                    <label>MILEAGE: </label>
                                                    <input type="number" placeholder="" name="vehMlgeNb"
                                                           value={VcrFromData.vehMlgeNb}
                                                           onChange={onChangeHandlerInt}/>
                                                </div>
                                                <div className="fds-layout-grid__cell--span-2">
                                                    <label>IGNITION KEY NO: </label>
                                                    1234
                                                </div>
                                                <div className="fds-layout-grid__cell--span-2">
                                                    <label>COLOR: </label>
                                                    RED
                                                </div>
                                                <div className="fds-layout-grid__cell--span-5">
                                                    <div className="form-group">
                                                        <label>GENERAL CONDITION:</label>
                                                        <fieldset className="fmc-check fmc-check--horizontal">
                                                            {GeneralConditionOptions.map(options => (
                                                                <label key={options.key}>
                                                                    <input type="radio" name="genCndtnCd"
                                                                           value={options.key}
                                                                           onChange={onChangeHandlerInt}
                                                                           checked={VcrFromData.genCndtnCd === options.key}/>
                                                                    <span className="r-0">{options.value}</span>
                                                                </label>
                                                            ))}
                                                        </fieldset>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*----Keys-----*/}
                                        <div>
                                            <div className="fds-layout-grid p-0">
                                                <div className="fds-layout-grid__cell--span-12">
                                                    {/* Flex container to align the key and radio buttons horizontally */}
                                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                                        <div style={{marginRight: '20px'}}>KEYS:</div>
                                                        <fieldset className="fmc-check fmc-check--horizontal"
                                                                  style={{display: 'flex', gap: '20px'}}>
                                                            <label>
                                                                <input
                                                                    type="radio"
                                                                    name="keysInd"
                                                                    value="Y"
                                                                    checked={VcrFromData.keysInd === 'Y'}
                                                                    onChange={onChangeHandler}
                                                                />
                                                                <span>YES</span>
                                                            </label>
                                                            <label>
                                                                <input
                                                                    type="radio"
                                                                    name="keysInd"
                                                                    value="N"
                                                                    checked={VcrFromData.keysInd === 'N'}
                                                                    onChange={onChangeHandler}
                                                                />
                                                                <span>NO</span>
                                                            </label>
                                                        </fieldset>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <hr/>
                                    {/*--------Accessories------*/}
                                    <div>
                                        <div className="fds-layout-grid p-0">
                                            <div className="fds-layout-grid__inner p-0 ">
                                                <div className="fds-layout-grid__cell--span-2">
                                                    <legend>ACCESSORIES:</legend>
                                                </div>
                                                <div className="fds-layout-grid__cell--span-10">
                                                    <div className="fds-layout-grid__inner p-0 ">
                                                        <div className="fds-layout-grid__cell--span-3 fmc-check">
                                                            <label className="l-0">
                                                                <input type="checkbox" name="accAtrnsIn" value="Y"
                                                                       checked={VcrFromData.accAtrnsIn === "Y"}
                                                                       onChange={onChangeHandler}/>
                                                                <span className="r-1">Auto Trans</span>
                                                            </label>
                                                            <label className="l-0">
                                                                <input type="checkbox" name="accAcIn" value="Y"
                                                                       checked={VcrFromData.accAcIn === "Y"}
                                                                       onChange={onChangeHandler}/>
                                                                <span className="r-1">A/C</span>
                                                            </label>
                                                            <label className="l-0">
                                                                <input type="checkbox" name="accVnlRfIn" value="Y"
                                                                       checked={VcrFromData.accVnlRfIn === "Y"}
                                                                       onChange={onChangeHandler}/>
                                                                <span className="r-1">Venyl Roof</span>
                                                            </label>

                                                        </div>
                                                        <div className="fds-layout-grid__cell--span-3 fmc-check">
                                                            <label className="l-0">
                                                                <input type="checkbox" name="accPwrSeatIn" value="Y"
                                                                       checked={VcrFromData.accPwrSeatIn === "Y"}
                                                                       onChange={onChangeHandler}/>
                                                                <span className="r-1">P. Seats</span>
                                                            </label>
                                                            <label className="l-0">
                                                                <input type="checkbox" name="accPwrWinIn" value="Y"
                                                                       checked={VcrFromData.accPwrWinIn === "Y"}
                                                                       onChange={onChangeHandler}/>
                                                                <span className="r-1">P. Window</span>
                                                            </label>
                                                            <label className="l-0">
                                                                <input type="checkbox" name="accFmRadIn" value="Y"
                                                                       checked={VcrFromData.accFmRadIn === "Y"}
                                                                       onChange={onChangeHandler}/>
                                                                <span className="r-1">FM Radio</span>
                                                            </label>
                                                        </div>
                                                        <div className="fds-layout-grid__cell--span-3 fmc-check">
                                                            <label className="l-0">
                                                                <input type="checkbox" name="accAftcRdoIn" value="Y"
                                                                       checked={VcrFromData.accAftcRdoIn === "Y"}
                                                                       onChange={onChangeHandler}/>
                                                                <span className="r-1">AM/FM/TAPE/CD</span>
                                                            </label>
                                                            <label className="l-0">
                                                                <input type="checkbox" name="accTiltIn" value="Y"
                                                                       checked={VcrFromData.accTiltIn === "Y"}
                                                                       onChange={onChangeHandler}/>
                                                                <span className="r-1">Tilt Wheel</span>
                                                            </label>
                                                            <label className="l-0">
                                                                <input type="checkbox" name="accCrsCtlIn" value="Y"
                                                                       checked={VcrFromData.accCrsCtlIn === "Y"}
                                                                       onChange={onChangeHandler}/>
                                                                <span className="r-1">Speed Control</span>
                                                            </label>
                                                        </div>
                                                        <div className="fds-layout-grid__cell--span-3 fmc-check">
                                                            <label className="l-0">
                                                                <input type="checkbox" name="accDieselIn" value="Y"
                                                                       checked={VcrFromData.accDieselIn === "Y"}
                                                                       onChange={onChangeHandler}/>
                                                                <span className="r-1">Diesel</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    Mechanical Glass Tire and interior info
                                    <div>
                                        <div className="fds-layout-grid p-0">
                                            <div className="fds-layout-grid__inner p-0 ">
                                                <div className="fds-layout-grid__cell--span-2">
                                                    <div className="form-group">
                                                        <label>MECHANICAL:</label>
                                                        <fieldset className="fmc-check" style={{marginTop: 1 + 'px'}}>
                                                            {MechanicalConditionOptions.map(options => (
                                                                <label className="l-0" key={options.key}>
                                                                    <input type="radio" name="mechCndtnCd"
                                                                           value={options.key}
                                                                           onChange={onChangeHandlerInt}
                                                                           checked={VcrFromData.mechCndtnCd === options.key}/>
                                                                    <span className="r-1">{options.value}</span>
                                                                </label>
                                                            ))}
                                                        </fieldset>
                                                    </div>
                                                </div>
                                                <div className="fds-layout-grid__cell--span-2">
                                                    <div className="form-group">
                                                        <label>GLASS:</label>
                                                        <fieldset className="fmc-check" style={{marginTop: 1 + 'px'}}>
                                                            {Object.entries(GlassConditionOptions).map(([key, value], i) => (
                                                                <label className="l-0" key={key}>
                                                                    <input type="radio" name="glsCndtnCd" value={key}
                                                                           onChange={onChangeHandlerInt}
                                                                           checked={VcrFromData.glsCndtnCd === parseInt(key)}/>
                                                                    <span className="r-1">{value}</span>
                                                                </label>
                                                            ))}
                                                            <div className="form-group">
                                                                <label>INDICATE LOCATION:</label>
                                                                <input type="text" placeholder="" name="glsBrknDs"
                                                                       value={VcrFromData.glsBrknDs}
                                                                       onChange={onChangeHandler}/>
                                                            </div>
                                                        </fieldset>
                                                    </div>
                                                </div>
                                                <div className="fds-layout-grid__cell--span-4"
                                                     style={{textAlign: "center"}}>
                                                    <div className="form-group">
                                                        <label style={{marginLeft: -15 + 'em'}}>TIRES:</label>
                                                        <div>
                                                            <label style={{paddingRight: 0.70 + 'em'}}>Left
                                                                Front</label>
                                                            <select name="lfTireCndtnCd" id="vehicle-select"
                                                                    className="s-1"
                                                                    onChange={onChangeHandlerInt}
                                                                    value={VcrFromData.lfTireCndtnCd}>
                                                                <option value=""></option>
                                                                {TireConditionOptions.map(options => (
                                                                    <option key={options.key} value={options.key}>
                                                                        {options.value}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        <div>
                                                            <label style={{paddingRight: 1.15 + 'em'}}>Left Rear</label>
                                                            <select name="lrTireCndtnCd" id="vehicle-select"
                                                                    className="s-1"
                                                                    onChange={onChangeHandlerInt}
                                                                    value={VcrFromData.lrTireCndtnCd}>
                                                                <option value=""></option>
                                                                {TireConditionOptions.map((options) => (
                                                                    <option key={options.key} value={options.key}>
                                                                        {options.value}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        <div>
                                                            <label>Right Front</label>
                                                            <select name="rfTireCndtnCd" id="vehicle-select"
                                                                    className="s-1"
                                                                    onChange={onChangeHandlerInt}
                                                                    value={VcrFromData.rfTireCndtnCd}>
                                                                <option value=""></option>
                                                                {TireConditionOptions.map((options) => (
                                                                    <option key={options.key} value={options.key}>
                                                                        {options.value}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        <div>
                                                            <label style={{paddingRight: 0.40 + 'em'}}>Right
                                                                Rear</label>
                                                            <select name="rrTireCndtnCd" id="vehicle-select"
                                                                    className="s-1"
                                                                    onChange={onChangeHandlerInt}
                                                                    value={VcrFromData.rrTireCndtnCd}>
                                                                <option value=""></option>
                                                                {TireConditionOptions.map((options) => (
                                                                    <option key={options.key} value={options.key}>
                                                                        {options.value}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        <div>
                                                            <label style={{paddingRight: 3 + 'em'}}>Spare</label>
                                                            <select name="spTireCndtnCd" id="vehicle-select"
                                                                    className="s-1"
                                                                    onChange={onChangeHandlerInt}
                                                                    value={VcrFromData.spTireCndtnCd}>
                                                                <option value=""></option>
                                                                {TireConditionOptions.map((options) => (
                                                                    <option key={options.key} value={options.key}>
                                                                        {options.value}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="fds-layout-grid__cell--span-4">
                                                    <div className="form-group">
                                                        <label>INTERIOR:</label>
                                                        <fieldset className="fmc-check" style={{marginTop: 1 + 'px'}}>
                                                            <option value=""></option>
                                                            {InteriorConditionOptions.map((options) => (
                                                                <label className="l-0" key={options.key}>
                                                                    <input type="radio" name="intCndtnCd"
                                                                           value={options.key}
                                                                           onChange={onChangeHandlerInt}
                                                                           checked={VcrFromData.intCndtnCd === options.key}/>
                                                                    <span className="r-1">{options.value}</span>
                                                                </label>
                                                            ))}
                                                            <div>
                                                                <label>EXPLANATION:</label>
                                                                <input type="text" placeholder="" name="intExplDs"
                                                                       value={VcrFromData.intExplDs}
                                                                       onChange={onChangeHandler}/>
                                                            </div>
                                                        </fieldset>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    {/*---Body condition details---*/}
                                    <div>
                                        <div className="fds-layout-grid p-0">
                                            <div className="fds-layout-grid__inner p-0 ">
                                                <div className="fds-layout-grid__cell--span-4">
                                                    <div className="form-group">
                                                        <label>BODY:</label>
                                                        <div>
                                                            <label style={{paddingRight: 2.6 + 'em'}}>Body</label>
                                                            <select name="ovrlBodyCndtnCd" id="vehicle-select"
                                                                    className="s-1"
                                                                    onChange={onChangeHandlerInt}
                                                                    value={VcrFromData.ovrlBodyCndtnCd}>
                                                                <option value=""></option>
                                                                {OverallConditionOptions.map(options => (
                                                                    <option key={options.key} value={options.key}>
                                                                        {options.value}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        <div>
                                                            <label style={{paddingRight: 2.5 + 'em'}}>Paint</label>
                                                            <select name="ovrlPntCndtnCd" id="vehicle-select"
                                                                    className="s-1"
                                                                    onChange={onChangeHandlerInt}
                                                                    value={VcrFromData.ovrlPntCndtnCd}>
                                                                <option value=""></option>
                                                                {OverallConditionOptions.map(options => (
                                                                    <option key={options.key} value={options.key}>
                                                                        {options.value}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        <div>
                                                            <label style={{paddingRight: 0.5 + 'em'}}>Bumpers</label>
                                                            <select name="ovrlBmprCndtnCd" id="vehicle-select"
                                                                    className="s-1"
                                                                    onChange={onChangeHandlerInt}
                                                                    value={VcrFromData.ovrlBmprCndtnCd}>
                                                                <option value=""></option>
                                                                {OverallConditionOptions.map(options => (
                                                                    <option key={options.key} value={options.key}>
                                                                        {options.value}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="fds-layout-grid__cell--span-8">
                                                    <div className="form-group">
                                                        <label>Dent/Rust Location:</label>
                                                        <div className="fds-layout-grid__inner p-0 ">
                                                            <div className="fds-layout-grid__cell--span-4 fmc-check">
                                                                <label>Driver Side:</label>
                                                                <label className="l-0">
                                                                    <input type="checkbox" name="cndtnDsfQpIn" value="Y"
                                                                           checked={VcrFromData.cndtnDsfQpIn === "Y"}
                                                                           onChange={onChangeHandler}/>
                                                                    <span className="r-1">Front Quarter Panel</span>
                                                                </label>
                                                                <label className="l-0">
                                                                    <input type="checkbox" name="cndtnDsfDrIn" value="Y"
                                                                           checked={VcrFromData.cndtnDsfDrIn === "Y"}
                                                                           onChange={onChangeHandler}/>
                                                                    <span className="r-1">Front Door</span>
                                                                </label>
                                                                <label className="l-0">
                                                                    <input type="checkbox" name="cndtnDsrDrIn" value="Y"
                                                                           checked={VcrFromData.cndtnDsrDrIn === "Y"}
                                                                           onChange={onChangeHandler}/>
                                                                    <span className="r-1">Rear Door</span>
                                                                </label>
                                                                <label className="l-0">
                                                                    <input type="checkbox" name="cndtnDsrQpIn" value="Y"
                                                                           checked={VcrFromData.cndtnDsrQpIn === "Y"}
                                                                           onChange={onChangeHandler}/>
                                                                    <span className="r-1">Rear Quarter Panel</span>
                                                                </label>
                                                            </div>
                                                            <div className="fds-layout-grid__cell--span-4 fmc-check">
                                                                <label>Passenger Side:</label>
                                                                <label className="l-0">
                                                                    <input type="checkbox" name="cndtnPsfQpIn" value="Y"
                                                                           checked={VcrFromData.cndtnPsfQpIn === "Y"}
                                                                           onChange={onChangeHandler}/>
                                                                    <span className="r-1">Front Quarter Panel</span>
                                                                </label>
                                                                <label className="l-0">
                                                                    <input type="checkbox" name="cndtnPsfDrIn" value="Y"
                                                                           checked={VcrFromData.cndtnPsfDrIn === "Y"}
                                                                           onChange={onChangeHandler}/>
                                                                    <span className="r-1">Front Door</span>
                                                                </label>
                                                                <label className="l-0">
                                                                    <input type="checkbox" name="cndtnPsrDrIn" value="Y"
                                                                           checked={VcrFromData.cndtnPsrDrIn === "Y"}
                                                                           onChange={onChangeHandler}/>
                                                                    <span className="r-1">Rear Door</span>
                                                                </label>
                                                                <label className="l-0">
                                                                    <input type="checkbox" name="cndtnPsrQpIn" value="Y"
                                                                           checked={VcrFromData.cndtnPsrQpIn === "Y"}
                                                                           onChange={onChangeHandler}/>
                                                                    <span className="r-1">Rear Quarter Panel</span>
                                                                </label>
                                                            </div>
                                                            <div className="fds-layout-grid__cell--span-2 fmc-check">
                                                                <label>Top:</label>
                                                                <label className="l-0">
                                                                    <input type="checkbox" name="cndtnHoodIn" value="Y"
                                                                           checked={VcrFromData.cndtnHoodIn === "Y"}
                                                                           onChange={onChangeHandler}/>
                                                                    <span className="r-1">Hood</span>
                                                                </label>
                                                                <label className="l-0">
                                                                    <input type="checkbox" name="cndtnRoofIn" value="Y"
                                                                           checked={VcrFromData.cndtnRoofIn === "Y"}
                                                                           onChange={onChangeHandler}/>
                                                                    <span className="r-1">Roof</span>
                                                                </label>
                                                                <label className="l-0">
                                                                    <input type="checkbox" name="cndtnTrnkIn" value="Y"
                                                                           checked={VcrFromData.cndtnTrnkIn === "Y"}
                                                                           onChange={onChangeHandler}/>
                                                                    <span className="r-1">Trunk</span>
                                                                </label>
                                                            </div>
                                                            <div className="fds-layout-grid__cell--span-1 fmc-check">
                                                                <label>Bumper:</label>
                                                                <label className="l-0">
                                                                    <input type="checkbox" name="cndtnBumpfIn" value="Y"
                                                                           checked={VcrFromData.cndtnBumpfIn === "Y"}
                                                                           onChange={onChangeHandler}/>
                                                                    <span className="r-1">Front</span>
                                                                </label>
                                                                <label className="l-0">
                                                                    <input type="checkbox" name="cndtnBumprIn" value="Y"
                                                                           checked={VcrFromData.cndtnBumprIn === "Y"}
                                                                           onChange={onChangeHandler}/>
                                                                    <span className="r-1">Rear</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    {/*---Remarks-- */}
                                    <div className="p-0">
                                        <div className="fds-layout-grid p-0">
                                            <div className="form-group">
                                                <label>REMARKS/MISSING EQUIPMENT :</label>
                                                <input type="text" placeholder="" className="i-1" name="msgEquipDs"
                                                       value={VcrFromData.msgEquipDs}
                                                       onChange={onChangeHandler}/>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div className="p-0">
                                        <div className="fds-layout-grid p-0">
                                            <div>
                                                <label>ESTIMATED INSURANCE DAMAGE AMOUNT :</label>
                                                <input type='text' placeholder="" style={{"width": 10 + 'em'}}
                                                       name="dmgEstAm"
                                                       value={VcrFromData.dmgEstAm}
                                                       onChange={onChangeHandlerInt}/>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    {/*VEHICLE RECOVERED FROM*/}
                                    <div>
                                        <div className="fds-layout-grid p-0">
                                            <div className="fds-layout-grid__inner p-0 ">
                                                <div className="fds-layout-grid__cell--span-6">
                                                    <div className="form-group">
                                                        <label>Vechicle Recovred From</label>
                                                        <div className="fmc-check--horizontal">
                                                            <label className="l-0">
                                                                <input type="radio" name="vehRcvrdFromNm"
                                                                       value="CUSTOMER"
                                                                       onChange={onChangeHandler}
                                                                       checked={VcrFromData.vehRcvrdFromNm === "CUSTOMER"}/>
                                                                <span className="r-1">CUSTOMER</span>
                                                            </label>
                                                            <label className="l-0">
                                                                <input type="radio" name="vehRcvrdFromNm" value="OTHER"
                                                                       onChange={onChangeHandler}
                                                                       checked={VcrFromData.vehRcvrdFromNm === "OTHER"}/>
                                                                <span className="r-1">OTHER (SPECIFY)</span>
                                                            </label>
                                                            <label className="l-0">
                                                                <input type="text" id="vehRcvOthrSpcfy"
                                                                       style={{"width": 10 + 'em'}}
                                                                       name="vehRcvOthrSpcfy"
                                                                       value={VcrFromData.vehRcvOthrSpcfy}
                                                                       disabled={VcrFromData.vehRcvrdFromNm === "CUSTOMER" && VcrFromData.vehRcvrdFromNm !== ""}
                                                                       onChange={onChangeHandler}/>
                                                            </label>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="fds-layout-grid__cell--span-6">
                                                    <div className="form-group">
                                                        <label>Recovred From</label>
                                                        <div>
                                                            <label style={{paddingRight: 0.5 + 'em'}}>ADDRESS:</label>
                                                            <input type="text" className="s-1" name="vehRcvrdAddrTx"
                                                                   value={VcrFromData.vehRcvrdAddrTx}
                                                                   onChange={onChangeHandler}/>
                                                        </div>
                                                        <div>
                                                            <label style={{paddingRight: 3.4 + 'em'}}>CITY:</label>
                                                            <input type="text" className="s-1" name="vehRcvrdCityNm"
                                                                   value={VcrFromData.vehRcvrdCityNm}
                                                                   onChange={onChangeHandler}/>
                                                        </div>
                                                        <div>
                                                            <label className="l-0">
                                                            <span className="r-1"
                                                                  style={{paddingRight: 2.3 + 'em'}}>STATE:</span>
                                                                <select name="vehRcvrdStAbbrCd" className="s-2"
                                                                        onChange={onChangeHandler}
                                                                        value={VcrFromData.vehRcvrdStAbbrCd}>
                                                                    <option value=""></option>
                                                                    {StateOrProvince?.map(options => (
                                                                        <option key={options.stAbbrCd}
                                                                                value={options.stAbbrCd}>
                                                                            {options.stAbbrCd}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </label>
                                                            <label className="l-0">
                                                                <span className="r-1">ZIP: </span>
                                                                <input type="text" className="s-3" name="vehRcvrdZipCd"
                                                                       value={VcrFromData.vehRcvrdZipCd}
                                                                       onChange={onChangeHandler}/>
                                                            </label>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    {/*Customer sign details*/}
                                    <div>
                                        <div className="fds-layout-grid p-0">
                                            <div className="fds-layout-grid__inner p-0 ">
                                                <div className="fds-layout-grid__cell--span-8">
                                                    <div className="fmc-check">
                                                        <label>WAS RELEASED SIGNED BY CUSTOMER? </label>
                                                        <label className="l-0">
                                                            <input type="radio" name="cstRlsIn" value="Y"
                                                                   onChange={onChangeHandler}
                                                                   checked={VcrFromData.cstRlsIn === "Y"}/>
                                                            <span className="r-1">YES</span>
                                                        </label>
                                                        <label className="l-0">
                                                            <input type="radio" name="cstRlsIn" value="N"
                                                                   onChange={onChangeHandler}
                                                                   checked={VcrFromData.cstRlsIn === "N"}/>
                                                            <span className="r-1">NO.    IF NO, EXPLAIN  </span>
                                                            <input type="text" className="s-0" name="cstRlsDs"
                                                                   value={VcrFromData.cstRlsDs}
                                                                   disabled={VcrFromData.cstRlsIn === "Y"}
                                                                   onChange={onChangeHandler}/>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div>
                                        <div className="fds-layout-grid p-0">
                                            <div className="fds-layout-grid__inner p-0 ">
                                                <div className="fds-layout-grid__cell--span-4">
                                                    <div className="form-group">
                                                        <label>IF NO, WERE PROPER AUTHORITIES NOTIFIED?</label>
                                                        <div className="fmc-check--horizontal">
                                                            <label className="l-0">
                                                                <input type="radio" name="athNtfyIn" value="N"
                                                                       onChange={onChangeHandler}
                                                                       checked={VcrFromData.athNtfyIn === "N"}/>
                                                                <span className="r-1">NO. IF NO, EXPLAIN BELOW</span>
                                                            </label>
                                                            <label className="l-0">
                                                                <input type="radio" name="athNtfyIn" value="Y"
                                                                       checked={VcrFromData.athNtfyIn === "Y"}
                                                                       onChange={onChangeHandler}/>
                                                                <span className="r-1">YES</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="fds-layout-grid__cell--span-6">
                                                    <div className="form-group">
                                                        <label>AUTHORITIES NOTIFIED:</label>
                                                        <div>
                                                            <label style={{paddingRight: 2.6 + 'em'}}>NAME:</label>
                                                            <input type="text" className="s-1" name="athNtfyNm"
                                                                   value={VcrFromData.athNtfyNm}
                                                                   disabled={VcrFromData.athNtfyIn === "N"}
                                                                   onChange={onChangeHandler}/>
                                                        </div>
                                                        <div>
                                                            <label style={{paddingRight: 0.5 + 'em'}}>ADDRESS:</label>
                                                            <input type="text" className="s-1" name="athNtfyAddrTx"
                                                                   value={VcrFromData.athNtfyAddrTx}
                                                                   disabled={VcrFromData.athNtfyIn === "N"}
                                                                   onChange={onChangeHandler}/>
                                                        </div>
                                                        <div>
                                                            <label style={{paddingRight: 3.4 + 'em'}}>CITY:</label>
                                                            <input type="text" className="s-1" name="athNtfyCityNm"
                                                                   value={VcrFromData.athNtfyCityNm}
                                                                   disabled={VcrFromData.athNtfyIn === "N"}
                                                                   onChange={onChangeHandler}/>
                                                        </div>
                                                        <div>
                                                            <label className="l-0">
                                                            <span className="r-1"
                                                                  style={{paddingRight: 2.3 + 'em'}}>STATE:</span>
                                                                <select name="athNtfyStAbbrCd" className="s-2"
                                                                        onChange={onChangeHandler}
                                                                        disabled={VcrFromData.athNtfyIn === "N"}
                                                                        value={VcrFromData.athNtfyStAbbrCd}>
                                                                    <option value=""></option>
                                                                    {StateOrProvince?.map(options => (
                                                                        <option key={options.stAbbrCd}
                                                                                value={options.stAbbrCd}>
                                                                            {options.stAbbrCd}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </label>
                                                            <label className="l-0">
                                                                <span className="r-1">ZIP: </span>
                                                                <input type="text" className="s-3" name="athNtfyZipCd"
                                                                       value={VcrFromData.athNtfyZipCd}
                                                                       disabled={VcrFromData.athNtfyIn === "N"}
                                                                       onChange={onChangeHandler}/>
                                                            </label>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    {/*OTHER COMMENTS*/}
                                    <div className="p-0">
                                        <div className="fds-layout-grid p-0">
                                            <div className="form-group">
                                                <label>OTHER COMMENTS :</label>
                                                <input type="text" placeholder=""
                                                       className="i-1" name="othCmtDs" style={{"height": 100 + 'px'}}
                                                       value={VcrFromData.othCmtDs}
                                                       onChange={onChangeHandler}/>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    {/*   vehicle located at */}
                                    <div>
                                        <div className="fds-layout-grid p-0">
                                            <div className="fds-layout-grid__inner p-0 ">
                                                <div className="fds-layout-grid__cell--span-12">
                                                    <label>VECHICLE LOCATED AT:</label>
                                                    <div className="fmc-check">
                                                    </div>

                                                </div>
                                                <div className="fds-layout-grid__cell--span-6">
                                                    <div className="form-group">
                                                        <div className="fmc-check">
                                                            <label className="l-0">
                                                                <input type="radio" name="vehLoc" value="AUCTION"
                                                                       checked={VehLocData.vehLoc === "AUCTION"}
                                                                       onChange={onChangeHandlerVehLoc}/>
                                                                <span className="r-1">AUCTION</span>
                                                                <select name="auctNm" id="vehicle-select"
                                                                        className="s-4"
                                                                     onChange={onChangeHandlerVehLoc}
                                                                        disabled={VehLocData.vehLoc !== "AUCTION" & VehLocData.vehLoc !== ""}
                                                                        value={VehLocData.auctNm}>
                                                                    <option value=""></option>                                                                    
                                                                    {auctionLocationOptions.map(options => (
                                                                    <option key={options.auctLocDs} value={options.auctLocCd}>
                                                                        {options.auctLocDs}
                                                                    </option>
                                                                ))}
                                                                </select>
                                                            </label>

                                                            <label className="l-0">
                                                                <input type="radio" name="vehLoc" value="CONTRACTOR"
                                                                       checked={VehLocData.vehLoc === "CONTRACTOR"}
                                                                       onChange={onChangeHandlerVehLoc}/>
                                                                <span className="r-1">CONTRACTOR</span>
                                                            </label>
                                                            <label className="l-0">
                                                                <input type="radio" name="vehLoc" value="DEALER"
                                                                       checked={VehLocData.vehLoc === "DEALER"}
                                                                       onChange={onChangeHandlerVehLoc}/>
                                                                <span className="r-1">DEALER</span>
                                                            </label>
                                                            <label className="l-0">
                                                                <input type="radio" name="vehLoc" value="OTHER"
                                                                       checked={VehLocData.vehLoc === "OTHER"}
                                                                       onChange={onChangeHandlerVehLoc}/>
                                                                <span className="r-1">OTHER</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="fds-layout-grid__cell--span-6">
                                                    <div className="form-group">
                                                        <div>
                                                            <label style={{paddingRight: 6 + 'em'}}></label>
                                                            <input type="hidden" className="s-1"/>
                                                        </div>
                                                        <div>
                                                            <label style={{paddingRight: 6 + 'em'}}>NAME:</label>
                                                            <input type="text" className="s-1" name="vehLocNm"
                                                                   value={VehLocData.vehLocNm}
                                                                   disabled={VehLocData.vehLoc === "AUCTION" & VehLocData.vehLoc !== ""}
                                                                   onChange={onChangeHandlerVehLoc}/>
                                                        </div>
                                                        <div>
                                                            <label style={{paddingRight: 0.5 + 'em'}}>ADDRESS
                                                                LINE1:</label>
                                                            <input type="text" className="s-1" name="addrLine1Tx"
                                                                   value={VehLocData.addrLine1Tx}
                                                                   disabled={VehLocData.vehLoc === "AUCTION" & VehLocData.vehLoc !== ""}
                                                                   onChange={onChangeHandlerVehLoc}/>
                                                        </div>
                                                        <div>
                                                            <label style={{paddingRight: 0.4 + 'em'}}>ADDRESS
                                                                LINE2:</label>
                                                            <input type="text" className="s-1" name="addrLine2Tx"
                                                                   value={VehLocData.addrLine2Tx}
                                                                   disabled={VehLocData.vehLoc === "AUCTION" & VehLocData.vehLoc !== ""}
                                                                   onChange={onChangeHandlerVehLoc}/>
                                                        </div>
                                                        <div>
                                                            <label style={{paddingRight: 7 + 'em'}}>CITY:</label>
                                                            <input type="text" className="s-1" name="cityNm"
                                                                   value={VehLocData.cityNm}
                                                                   disabled={VehLocData.vehLoc === "AUCTION" & VehLocData.vehLoc !== ""}
                                                                   onChange={onChangeHandlerVehLoc}/>
                                                        </div>
                                                        <div>
                                                            <label className="l-0">
                                                            <span className="r-1"
                                                                  style={{paddingRight: 5.8 + 'em'}}>STATE:</span>
                                                                <select name="stAbbrCd" className="s-2"
                                                                        value={VehLocData.stAbbrCd}
                                                                        onChange={onChangeHandlerVehLoc}
                                                                        disabled={VehLocData.vehLoc === "AUCTION" & VehLocData.vehLoc !== ""}>
                                                                    <option value=""></option>
                                                                    {StateOrProvince?.map(options => (
                                                                        <option key={options.stAbbrCd}
                                                                                value={options.stAbbrCd}>
                                                                            {options.stAbbrCd}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </label>
                                                            <label className="l-0">
                                                                <span className="r-1">ZIP: </span>
                                                                <input type="text" className="s-3" name="zipCd"
                                                                       value={VehLocData.zipCd}
                                                                       disabled={VehLocData.vehLoc === "AUCTION" & VehLocData.vehLoc !== ""}
                                                                       onChange={onChangeHandlerVehLoc}/>
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <label className="l-0">
                                                            <span className="r-1"
                                                                  style={{paddingRight: 5 + 'em'}}>PHONE:</span>
                                                                (<input type="text" className="s-2" name="phnCityAreaCd"
                                                                        value={VehLocData.phnCityAreaCd}
                                                                        disabled={VehLocData.vehLoc === "AUCTION" & VehLocData.vehLoc !== ""}
                                                                        onChange={onChangeHandlerVehLoc}/>)
                                                            </label>
                                                            <label className="l-0">
                                                                <input type="text" className="s-1" name="phnNb"
                                                                       value={VehLocData.phnNb}
                                                                       disabled={VehLocData.vehLoc === "AUCTION" & VehLocData.vehLoc !== ""}
                                                                       onChange={onChangeHandlerVehLoc}/>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                                <div style={{textAlign: "center", paddingTop: 3 + 'em', fontSize: "0.75em"}}>
                                    <span className="dot"></span>
                                    Prior to recording the mileage reflected on the vehicle's odometer. Consider the
                                    age,
                                    appearance and
                                    condition of the vehicle and look for the indicators of mileage discrepancies such
                                    as
                                    oil change
                                    stickers, repair work receipts, waranty book entries and odometer replacement
                                    stickers.
                                    Note any
                                    mileage
                                    discrepancies or inconsistencies in the Comments section.
                                </div>
                                <div style={{
                                    textAlign: "center",
                                    paddingTop: 1 + 'em',
                                    fontSize: "0.75em",
                                    margin: 2 + 'em'
                                }}>
                                    <span className="dot"></span>
                                    California Repossessions: In accordance with Section 7505. 115of Business and
                                    Professions code,
                                    this condition report is a general assessment of the collateral and does not
                                    including
                                    all damage or
                                    missing parts.
                                </div>
                                <section style={{padding: 2 + 'em'}}>
                                    <div className="fds-align--center">
                                        <span>FCNA 16779</span>
                                        <button type="submit" className="fmc-button"
                                                style={{marginLeft: 0.5 + 'em'}}>Continue
                                        </button>
                                        <button type="button" className="fmc-button" style={{marginLeft: 2 + 'em'}}
                                                onClick={reset}>Reset
                                        </button>
                                    </div>
                                </section>
                        </form>

                    </div>
                    )}
            </div>

        </>
    )
}

export default VehicleConditionReport;