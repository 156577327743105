import "./RadioButton.css"

const RadioButton = (props) => {
    console.log("RadioButton props", JSON.stringify(props));
    return (<>
        <div>
            <fieldset className={'fmc-check ' + props.className}>
                <legend>{props.label}</legend>
                {
                    props.options.map(option =>
                        <label key={option.name}>
                            <input type="radio" name={props.groupName} value={option.value} onChange={props.onChange}
                                   checked={props.radioVal === option.value}/>
                            <span>{option.name}</span>
                        </label>
                    )
                }
            </fieldset>
        </div>
    </>)
}

export default RadioButton;

