import React, {useEffect, useState} from "react";
import "./AssignmentDetails.css"
import AssignmentDetailsHeader from "../common/AssignmentDetailsHeader";
import {getUserInfoFromSession} from "../../Services/session.mgmt";
import {AssignmentService} from "../../Services/AssignmentService";

function AssignmentDetails({ bpiNo, onContinue }){

    const teamWrkBpiNb= bpiNo
    console.log(teamWrkBpiNb)

    const [ setLocalLocalError] = useState('')
    const [assignmentDetails, setAssignmentDetails] = useState([])
    const [setLoading] = useState(true);
    const [buyerCoBuyerInfo, setBuyerCoBuyerInfo] = useState([])
    const userInfo = getUserInfoFromSession()
    const VinNumber = "1FTFW1E51MFC15439"
    sessionStorage.setItem('buyerInfo', JSON.stringify(buyerCoBuyerInfo));

    useEffect(() => {
        const fetchData = async ()=>{
            try{
                AssignmentService.getAssignmentInfo(teamWrkBpiNb, userInfo.cntrctrNb).then(response => setAssignmentDetails(response.data));
                 AssignmentService.getBuyerCobuyerInfo(VinNumber).then(response => {
                     console.log(response)
                 })

                    setBuyerCoBuyerInfo([{
                        "customerAccount": {
                            "account": {
                                "receivableAccountNumber": 60436843,
                                "companyBusinessCode": 1,
                                "contractType": "Lse",
                                "accountStatus": "ACTIVE ACCOUNT",
                                "vehicleYear": "2021",
                                "vehicleMake": "FORD",
                                "vehicleModel": "F150",
                                "vehicleIdentificationNumber": "1FTFW1E51MFC15439"
                            },
                            "applicant": {
                                "applicantCode": "PR",
                                "firstName1": "JAMES",
                                "firstName2": "R",
                                "firstName3": null,
                                "lastName1": "BAKER",
                                "lastName2": null,
                                "title": null,
                                "generation": null,
                                "businessName": null,
                                "address": {
                                    "addressLine1": "7 EAGLE CAMP RD",
                                    "addressLine2": null,
                                    "city": "SOUTH HERO",
                                    "stateCode": "VT",
                                    "otherCountry": null,
                                    "otherPostCode": null,
                                    "otherState": null,
                                    "postCode": "05486-4403",
                                    "country": "USA"
                                }
                            }
                        }
                    }])
                //     console.log(response.data)
                // });
            }catch (err){
                // If an error occurs, handle it and set a custom message
                if (err.response) {
                    // Server responded with a status other than 200 range
                    setLocalLocalError(`Error: ${err.response.status} - ${err.response.data.message || 'Something went wrong'}`);
                } else if (err.request) {
                    // Request was made but no response was received
                    setLocalLocalError('Error: No response received from the server.');
                } else {
                    // Other errors like setting up the request
                    setLocalLocalError(`Error: ${err.message}`);
                }
            }finally {
                setLoading(false);
            }
        };
        fetchData();

    }, [teamWrkBpiNb]);

    return(
        <>
            <AssignmentDetailsHeader bpiNo = {teamWrkBpiNb}/>
            <br/>
            <div>
                {buyerCoBuyerInfo.map((buyerCoBuyerInfo)=> {
                    let applicant = buyerCoBuyerInfo.customerAccount.applicant
                    return (
                        <section key={applicant.firstName1+'-'+applicant.firstName2+'-'+applicant.firstName3}
                            className="fmc-global-attribute-bar fmc-global-attribute-bar--vr-md fmc-px-3 fmc-pb-3-sm fmc-py-3-md fmc-py-3-lg fmc-py-3-xl fds-color__bg--white"
                        >
                            <div className="fds-weight--bold">Buyer Information:</div>
                            <div className="fds-layout-gri p-0">
                                <div className="fds-layout-grid__inner p-0 ">
                                    <div className="fds-layout-grid__cell--span-12">Name: {applicant.firstName1} {applicant.firstName2} {applicant.firstName3}</div>
                                </div>
                            </div>
                            <div className="fds-layout-gri p-0">
                                <div className="fds-layout-grid__inner p-0 ">
                                    <div className="fds-layout-grid__cell--span-6">Address: {applicant.address.addressLine1} {applicant.address.addressLine2} {applicant.address.city} {applicant.address.stateCode} {applicant.address.otherCountry} {applicant.address.otherPostCode} {applicant.address.otherState} {applicant.address.postCode} {applicant.address.country}</div>
                                    <div className="fds-layout-grid__cell--span-6">Employer: </div>
                                </div>
                            </div>
                            <div className="fds-layout-gri p-0">
                                <div className="fds-layout-grid__inner p-0 ">
                                    <div className="fds-layout-grid__cell--span-6">Phone: </div>
                                    <div className="fds-layout-grid__cell--span-6">Phone: </div>
                                </div>
                            </div>
                        </section>
                    );

                })}
                {buyerCoBuyerInfo.map((buyerCoBuyerInfo)=> {
                    if(buyerCoBuyerInfo.customerAccount.applicant.applicantCode === "PR"){
                        return null
                    }else{
                        let applicant = buyerCoBuyerInfo.customerAccount.applicant
                        return (
                            <section key={applicant.firstName1+'-'+applicant.firstName2+'-'+applicant.firstName3}
                                className="fmc-global-attribute-bar fmc-global-attribute-bar--vr-md fmc-px-3 fmc-pb-3-sm fmc-py-3-md fmc-py-3-lg fmc-py-3-xl fds-color__bg--white"
                            >
                                <div className="fds-weight--bold">Co-Buyer Information:</div>
                                <div className="fds-layout-gri p-0">
                                    <div className="fds-layout-grid__inner p-0 ">
                                        <div className="fds-layout-grid__cell--span-12">Name: {applicant.firstName1} {applicant.firstName2} {applicant.firstName3}</div>
                                    </div>
                                </div>
                                <div className="fds-layout-gri p-0">
                                    <div className="fds-layout-grid__inner p-0 ">
                                        <div className="fds-layout-grid__cell--span-6">Address: {applicant.address.addressLine1} {applicant.address.addressLine2} {applicant.address.city} {applicant.address.stateCode} {applicant.address.otherCountry} {applicant.address.otherPostCode} {applicant.address.otherState} {applicant.address.postCode} {applicant.address.country}</div>
                                        <div className="fds-layout-grid__cell--span-6">Employer: </div>
                                    </div>
                                </div>
                                <div className="fds-layout-gri p-0">
                                    <div className="fds-layout-grid__inner p-0 ">
                                        <div className="fds-layout-grid__cell--span-6">Phone: </div>
                                        <div className="fds-layout-grid__cell--span-6">Phone: </div>
                                    </div>
                                </div>
                            </section>
                        );
                    }

                })}
                {assignmentDetails.map((assignmentDetails) => {
                    return (
                        <section key={assignmentDetails.recvrMthdCdDs}
                            className="fmc-global-attribute-bar fmc-global-attribute-bar--vr-md fmc-px-3 fmc-pb-3-sm fmc-py-3-md fmc-py-3-lg fmc-py-3-xl fds-color__bg--white"
                        >
                            <div className="fds-weight--bold">Assignment Information:</div>
                            <div className="fds-layout-gri p-0">
                                <div className="fds-layout-grid__inner p-0 ">
                                    <div className="fds-layout-grid__cell--span-6">Type of surrender: {assignmentDetails.recvrMthdCdDs}</div>
                                    <div className="fds-layout-grid__cell--span-6">Fee: {assignmentDetails.acptCntrctrFeeAm}</div>
                                </div>
                            </div>
                            <div className="fds-layout-gri p-0">
                                <div className="fds-layout-grid__inner p-0 ">
                                    <div className="fds-layout-grid__cell--span-6">CSR Name: </div>
                                    <div className="fds-layout-grid__cell--span-6">Phone: </div>
                                </div>
                            </div>
                            <div className="fds-layout-gri p-0">
                                <div className="fds-layout-grid__inner p-0 ">
                                    <div className="fds-layout-grid__cell--span-6">Special Instructions /
                                        Directions: </div>
                                </div>
                            </div>
                        </section>
                    );

                })}
                <br/>
                <section>
                    <div className="center">
                        <button type="button" className="fmc-button" onClick={onContinue}>Continue</button>
                    </div>
                </section>
            </div>

        </>

    )
}

export default AssignmentDetails;