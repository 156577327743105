import Header from "../../../Components/Header/Header";
import Footer from "../../../Components/Footer/Footer";
import Checkbox from '../../../Components/Checkbox/Checkbox';
import Button from '../../../Components/Button/Button';
import Select from '../../../Components/Select/Select';
import Input from '../../../Components/Input/Input';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./AddContractorProfile.css";
import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {validate} from "./AddContractorValidation"
import {getDropdownValues, persistData} from './AddContractorApis'
import {getUserInfoFromSession} from "../../../Services/session.mgmt";
import Swal from 'sweetalert2';

function AddContractorProfile() {
  const navigate = useNavigate();
  const userInfo = getUserInfoFromSession();
  const country = userInfo.countryCd;
  const user = userInfo.userid;

  // Set Form Data
  const [effectiveDate, setEffectiveDate] = useState(new Date());
  const [expirDate, setExpirDate] = useState(new Date());
  const [busLcnsExpirDate, setBusLcnsExpirDate] = useState(new Date());
  const [insExpirDate, setInsExpirDate] = useState(new Date());

  const [addContractorVals, setAddContractorVals] = useState({
    contractorName: '',
    contractorLoginId: '',
    rdnInd: '1',
    gsdbCode: '',
    contractorTypeCode: '',
    busCenterLocation: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    email: '',
    taxId: '',
    alertPref: 'E',
    primaryContact: '',
    secondaryContact: '',
    primaryPhoneAreaCode: '',
    primaryPhoneNum: '',
    faxAreaCode: '',
    faxNum: '',
    afterHoursAreaCode: '',
    afterHoursNum: '',
    mobileAreaCode: '',
    mobileNum: '',
    primaryTextAreaCode: '',
    primaryTextNum: '',
    addPhone1Type: '',
    addPhone1AreaCode: '',
    addPhone1Num: '',
    addPhone2Type: '',
    addPhone2AreaCode: '',
    addPhone2Num: '',
    addPhone3Type: '',
    addPhone3AreaCode: '',
    addPhone3Num: '',
    bus_lcns_tx: '',
    ins_info_tx: '',
    other_info_tx: ''
  });

  const [priceStructureSets, setPriceStructureSets] = useState([{
    pcName: '',
    selfHelp: '',
    volContractor: '',
    dealerVolContractorFee: '',
    closeFee: '',
    contingency: 'C',
    delToAuct: 'D',
    gvw: 'M',
    clearPc: '',
    pcIndex: 1
  }]);
  
  const [contractorOptions, setContractorOptions] = useState();
  const [superBranchOptions, setSuperBranchOptions] = useState();
  const [stateOptions, setStateOptions] = useState();
  const [contactOptions, setContactOptions] = useState();

  // Hardcoded Values
  const cbOptions = [{name: "Clear this pricing structure", value: "c"}];
  const rdnOptions = [{name: "Yes", value: "1"},
                      {name: "No", value: "0"}];
  const contingencyOptions = [{name: "C", value: "C"},
                      {name: "N", value: "N"}];
  const deliveryOptions = [{name: "D", value: "D"},
                      {name: "N", value: "N"}];   
  const gvwOptions = [{name: "M", value: "M"},
                      {name: "A", value: "A"},
                      {name: "H", value: "H"},
                      {name: "N", value: "N"}];  
  const alertOptions = [{name: "Email", value: "E"},
                      {name: "Text via Phone", value: "T"},
                      {name: "Both Email and Text", value: "B"}]; 
  const [busCharCount, setBusCharCount] = useState(0);
  const [insCharCount, setInsCharCount] = useState(0);
  const [otherCharCount, setOtherCharCount] = useState(0);

  // Onload/UseEffect
  useEffect(() => {
    const getData = async () => {
      try {
        const {contractorTypes, superBranchCodes, stateCodes, contactCodes} = await getDropdownValues(country);
        // console.log(contractorTypes)
        // console.log(superBranchCodes)
        // console.log(stateCodes)
        // console.log(contactCodes)
        setContractorOptions(contractorTypes);
        setSuperBranchOptions(superBranchCodes);
        setStateOptions(stateCodes);
        setContactOptions(contactCodes);

        setAddContractorVals((prevData) => ({
          ...prevData,
          contractorTypeCode: contractorTypes.length > 0 ? contractorTypes[0].value : '',
          busCenterLocation: superBranchCodes.length > 0 ? superBranchCodes[0].value : '',
          state: stateCodes.length > 0 ? stateCodes[0].value : 0,
        }));

      }catch(err){
        console.log('Error fetching dropdown options')
        console.log(err);
      }
    }
    getData();
  },[country]);

  // Event functions
  const addClick = async () => {
    const errors = await validate(addContractorVals, priceStructureSets, country);
    if(errors){
      console.log(errors);
      Swal.fire({
        title: 'Error',
        html: errors,
        icon: 'error',
        confirmButtonText: 'Okay'
      });
    }else{
      const response = await persistData(addContractorVals, {
        "effectiveDate":effectiveDate,
        "expirDate":expirDate,
        "busLcnsExpirDate":busLcnsExpirDate,
        "insExpirDate":insExpirDate
      }, priceStructureSets, country, user);
      if(response !== 'Contractor data saved successfully'){
        Swal.fire({
          title: 'Error',
          html: response,
          icon: 'error',
          confirmButtonText: 'Okay'
        });
      }else{
        Swal.fire({
          title: 'Success',
          html: response,
          icon: 'success',
          confirmButtonText: 'Okay',
          didClose: () => {
            navigate(`/contractorprofile`);
          }
        });
      }
    }
  }

  const cancelClick = () => {
    navigate(`/contractorprofile`);
  }

  const addNewPCClick = () => {
    const newFields = {
      pcName: '',
      selfHelp: '',
      volContractor: '',
      dealerVolContractorFee: '',
      closeFee: '',
      contingency: 'C',
      delToAuct: 'D',
      gvw: 'M',
      clearPc: '',
      pcIndex: priceStructureSets.length+1
    }

    setPriceStructureSets([...priceStructureSets, newFields]);
  }

  const handleOnChange = (event) => {
    const {name, value} = event.target;
    setAddContractorVals((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }

  const handlePriceStructureChange = (event, index) => {
    let {name, value, type, checked} = event.target;
    if(type === 'checkbox' && checked){value = 'c';}
    else if (type === 'checkbox' && !checked){value = '';}
    setPriceStructureSets((prevSets) => 
      prevSets.map((item, idx) => 
        idx === index ? { ...item, [name]: value } : item
      )
    );
    console.log(priceStructureSets);
  }

  const countCharacters = (event) => {
    if(event.target.name === 'bus_lcns_tx'){
      setBusCharCount(event.target.value.length);
    }else if(event.target.name === 'ins_info_tx'){
      setInsCharCount(event.target.value.length);
    }else if(event.target.name === 'other_info_tx'){
      setOtherCharCount(event.target.value.length);
    }
	}

  return (<>
    <Header heading="Add Contractor ProfileJs" />
    <div className="margin10">
      <table border="5" style={{width: '100%'}}>
        <tbody>
        <tr>
          <td style={{width: "50%"}}>Company name:</td>
          <td style={{width: "50%"}}>
            <Input className="margin5 widthMinus10" 
              maxLength="60" 
              value={addContractorVals.contractorName}
              onChange={handleOnChange}
              name='contractorName'/>
          </td>
        </tr>
        <tr>
          <td>Contractor login id:</td>
          <td>
            <Input className="margin5 widthMinus10" 
              maxLength="9"
              value={addContractorVals.contractorLoginId}
              onChange={handleOnChange}
              name='contractorLoginId'/>
          </td>
        </tr>
        <tr>
          <td>RDN Indicator:</td>
          <td>
            <Select options={rdnOptions} 
              className="margin5 widthMinus10"
              onChange={handleOnChange}
              name='rdnInd'
              emptyDefault='no'/>
          </td>
        </tr>
        <tr>
          <td>GSDB Code:</td>
          <td>
            <Input className="margin5 widthMinus10"
              maxLength="80"
              value={addContractorVals.gsdbCode}
              onChange={handleOnChange}
              name='gsdbCode'/>
          </td>
        </tr>
        <tr>
          <td>Effective Date:</td>
          <td>
            <div className="datePicker margin5">
              <DatePicker selected={effectiveDate} 
                onChange={(date) => setEffectiveDate(date)} />
            </div>
          </td>
        </tr>
        <tr>
          <td>Expiration Date:</td>
          <td>
            <div className="datePicker margin5">
              <DatePicker selected={expirDate} 
                onChange={(date) => setExpirDate(date)} />
            </div>
          </td>
        </tr>
        <tr>
          <td>Contractor Type Code:</td>
          <td>
            <Select options={contractorOptions}
              className="margin5 widthMinus10"
              onChange={handleOnChange}
              name='contractorTypeCode'
              emptyDefault='no'/>
          </td>
        </tr>
        <tr>
          <td>Managing Business Center Location:</td>
          <td>
            <Select options={superBranchOptions} 
              className="margin5 widthMinus10"
              onChange={handleOnChange}
              name='busCenterLocation'
              emptyDefault='no'/>
          </td>
        </tr>
        <tr>
          <td>Address:</td>
          <td>
            <Input className="margin5 widthMinus10" 
              maxLength="38"
              value={addContractorVals.address}
              onChange={handleOnChange}
              name='address'/>
          </td>
        </tr>
        <tr>
          <td>City</td>
          <td>
            <Input className="margin5 widthMinus10" 
              maxLength="28"
              value={addContractorVals.city}
              onChange={handleOnChange}
              name='city'/>
          </td>
        </tr>
        <tr>
          <td>State, Zip:</td>
          <td>
            <Select options={stateOptions} 
              className="margin5 width30"
              onChange={handleOnChange}
              name='state'
              emptyDefault='no'/>
            <Input className="margin5 width60" 
              maxLength="11"
              value={addContractorVals.zip}
              onChange={handleOnChange}
              name='zip'/>
          </td>
        </tr>
        <tr>
          <td>Email Address:</td>
          <td>
            <Input className="margin5 widthMinus10" 
              maxLength="38"
              value={addContractorVals.email}
              onChange={handleOnChange}
              name='email'/>
          </td>
        </tr>
        <tr>
          <td>Federal Tax Id:</td>
          <td>
            <Input className="margin5 widthMinus10"
              maxLength="16"
              value={addContractorVals.taxId}
              onChange={handleOnChange}
              name='taxId'/>
          </td>
        </tr>
        {priceStructureSets.map((priceStructureSet,index) => (
          <React.Fragment key={priceStructureSet.pcIndex}>
        <tr>
          <td>Price Structure Name:</td>
          <td>
            <Input maxLength="24" 
              value={priceStructureSet.pcName}
              onChange={(event) => handlePriceStructureChange(event,index)}
              name='pcName'/>
            <Checkbox value={priceStructureSet.clearPc} 
              options={cbOptions}
              onChange={(event) => handlePriceStructureChange(event,index)}
              groupName='clearPc'/>
          </td>
        </tr>
        <tr>
          <td>&nbsp;&nbsp;&nbsp;Self Help Amount:</td>
          <td>
            <Input className="margin5 widthMinus10" 
              maxLength="15" 
              value={priceStructureSet.selfHelp}
              onChange={(event) => handlePriceStructureChange(event,index)}
              name='selfHelp'
              pattern='\d*'/>
          </td>
        </tr>
        <tr>
          <td>&nbsp;&nbsp;&nbsp;Voluntary Contractor Fee Amount:</td>
          <td>
            <Input className="margin5 widthMinus10" 
              maxLength="15" 
              value={priceStructureSet.volContractor}
              onChange={(event) => handlePriceStructureChange(event,index)}
              name='volContractor'
              pattern='\d*'/>
          </td>
        </tr>
        <tr>
          <td>&nbsp;&nbsp;&nbsp;Dealer Voluntary Contractor Fee Amount:</td>
          <td>
            <Input className="margin5 widthMinus10" 
              maxLength="15" 
              value={priceStructureSet.dealerVolContractorFee}
              onChange={(event) => handlePriceStructureChange(event,index)}
              name='dealerVolContractorFee'
              pattern='\d*'/>
          </td>
        </tr>
        <tr>
          <td>&nbsp;&nbsp;&nbsp;Close Fee Amount:</td>
          <td>
            <Input className="margin5 widthMinus10" 
              maxLength="15" 
              value={priceStructureSet.closeFee}
              onChange={(event) => handlePriceStructureChange(event,index)}
              name='closeFee'
              pattern='\d*'/>
          </td>
        </tr>
        <tr>
          <td>&nbsp;&nbsp;&nbsp;Contingency Indicator:</td>
          <td>
            <Select options={contingencyOptions} 
              className="margin5 widthMinus10" 
              value={priceStructureSet.contingency}
              onChange={(event) => handlePriceStructureChange(event,index)}
              name='contingency'
              emptyDefault='no'/>
          </td>
        </tr>
        <tr>
          <td>&nbsp;&nbsp;&nbsp;Delivery to Auction Indicator:</td>
          <td>
            <Select options={deliveryOptions} 
              className="margin5 widthMinus10" 
              value={priceStructureSet.delToAuct}
              onChange={(event) => handlePriceStructureChange(event,index)}
              name='delToAuct'
              emptyDefault='no'/>
          </td>
        </tr>
        <tr>
          <td>&nbsp;&nbsp;&nbsp;GVW Indicator:</td>
          <td>
            <Select options={gvwOptions} 
              className="margin5 widthMinus10" 
              value={priceStructureSet.gvw}
              onChange={(event) => handlePriceStructureChange(event,index)}
              name='gvw'
              emptyDefault='no'/>
          </td>
        </tr>
        </React.Fragment>
        ))}
        <tr>
          <td><Button  label="Add a New Price Structure" className="margin10px" onClick={() => addNewPCClick()} /></td>
          <td></td>
        </tr>
        <tr>
          <td>Primary Contact:</td>
          <td>
            <Input className="margin5 widthMinus10" 
              maxLength="60"
              value={addContractorVals.primaryContact}
              onChange={handleOnChange}
              name='primaryContact'/>
          </td>
        </tr>
        <tr>
          <td>Secondary Contact:</td>
          <td>
            <Input className="margin5 widthMinus10" 
              maxLength="60"
              value={addContractorVals.secondaryContact}
              onChange={handleOnChange}
              name='secondaryContact'/>
          </td>
        </tr>
        <tr>
          <td>Bus Lcns Expiration Date:</td>
          <td>
            <div className="datePicker margin5">
              <DatePicker selected={busLcnsExpirDate} onChange={(date) => setBusLcnsExpirDate(date)} />
            </div>
          </td>
        </tr>
        <tr>
          <td>Ins Info Expiration Date:</td>
          <td>
            <div className="datePicker margin5">
              <DatePicker selected={insExpirDate} onChange={(date) => setInsExpirDate(date)} />
            </div>
          </td>
        </tr>
        <tr>
          <td>Alert Preference:</td>
          <td>
            <Select options={alertOptions} 
              className="margin5 widthMinus10"
              onChange={handleOnChange}
              name='alertPref'
              emptyDefault='no'/>
          </td>
        </tr>
        <tr>
          <td>Primary Phone Number:</td>
          <td>
            <Input className="margin5 width20" 
              maxLength="3"
              value={addContractorVals.primaryPhoneAreaCode}
              onChange={handleOnChange}
              name='primaryPhoneAreaCode'
              pattern='\d*'/>
            <Input className="margin5 width40" 
              maxLength="7"
              value={addContractorVals.primaryPhoneNum}
              onChange={handleOnChange}
              name='primaryPhoneNum'
              pattern='\d*'/>
          </td>
        </tr>
        <tr>
          <td>Fax Number:</td>
          <td>
            <Input className="margin5 width20" 
              maxLength="3"
              value={addContractorVals.faxAreaCode}
              onChange={handleOnChange}
              name='faxAreaCode'
              pattern='\d*'/>
            <Input className="margin5 width40" 
              maxLength="7"
              value={addContractorVals.faxNum}
              onChange={handleOnChange}
              name='faxNum'
              pattern='\d*'/>
          </td>
        </tr>
        <tr>
          <td>After Hours Phone:</td>
          <td>
            <Input className="margin5 width20" 
              maxLength="3"
              value={addContractorVals.afterHoursAreaCode}
              onChange={handleOnChange}
              name='afterHoursAreaCode'
              pattern='\d*'/>
            <Input className="margin5 width40" 
              maxLength="7"
              value={addContractorVals.afterHoursNum}
              onChange={handleOnChange}
              name='afterHoursNum'
              pattern='\d*'/>
          </td>
        </tr>
        <tr>
          <td>Mobile Phone:</td>
          <td>
            <Input className="margin5 width20" 
              maxLength="3"
              value={addContractorVals.mobileAreaCode}
              onChange={handleOnChange}
              name='mobileAreaCode'
              pattern='\d*'/>
            <Input className="margin5 width40" 
              maxLength="7"
              value={addContractorVals.mobileNum}
              onChange={handleOnChange}
              name='mobileNum'
              pattern='\d*'/>
          </td>
        </tr>
        <tr>
          <td>Primary Text Phone:</td>
          <td>
            <Input className="margin5 width20" 
              maxLength="3"
              value={addContractorVals.primaryTextAreaCode}
              onChange={handleOnChange}
              name='primaryTextAreaCode'
              pattern='\d*'/>
            <Input className="margin5 width40" 
              maxLength="7"
              value={addContractorVals.primaryTextNum}
              onChange={handleOnChange}
              name='primaryTextNum'
              pattern='\d*'/>
          </td>
        </tr>
        <tr>
          <td>Additional Phones:</td>
          <td>
            <Select options={contactOptions} 
              className="margin5 width30"
              onChange={handleOnChange}
              name='addPhone1Type'/>
            <Input className="margin5 width20" 
              maxLength="3"
              value={addContractorVals.addPhone1AreaCode}
              onChange={handleOnChange}
              name='addPhone1AreaCode'
              pattern='\d*'/>
            <Input className="margin5 width40" 
              maxLength="7"
              value={addContractorVals.addPhone1Num}
              onChange={handleOnChange}
              name='addPhone1Num'
              pattern='\d*'/>
          </td>
        </tr>
        <tr>
          <td></td>
          <td>
            <Select options={contactOptions} 
              className="margin5 width30"
              onChange={handleOnChange}
              name='addPhone2Type'/>
            <Input className="margin5 width20" 
              maxLength="3"
              value={addContractorVals.addPhone2AreaCode}
              onChange={handleOnChange}
              name='addPhone2AreaCode'
              pattern='\d*'/>
            <Input className="margin5 width40" 
              maxLength="7"
              value={addContractorVals.addPhone2Num}
              onChange={handleOnChange}
              name='addPhone2Num'
              pattern='\d*'/>
          </td>
        </tr>
        <tr>
          <td></td>
          <td>
            <Select options={contactOptions} 
              className="margin5 width30"
              onChange={handleOnChange}
              name='addPhone3Type'/>
            <Input className="margin5 width20" 
              maxLength="3"
              value={addContractorVals.addPhone3AreaCode}
              onChange={handleOnChange}
              name='addPhone3AreaCode'
              pattern='\d*'/>
            <Input className="margin5 width40" 
              maxLength="7"
              value={addContractorVals.addPhone3Num}
              onChange={handleOnChange}
              name='addPhone3Num'
              pattern='\d*'/>
          </td>
        </tr>
        <tr>
          <td>Bus Lcns Text:</td>
          <td>
            <textarea cols="25" 
              name="bus_lcns_tx" 
              onKeyUp={countCharacters}
              onFocus={countCharacters}
              onPaste={countCharacters} 
              id="bus_lcns_tx" rows="3"
              maxLength="120"
              value={addContractorVals.bus_lcns_tx}
              onChange={handleOnChange}>
            </textarea>
            <br/>
            <span id="cRemaining_bus_lcns_tx">Remaining characters: {120-busCharCount} of 120</span>
          </td>
        </tr>
        <tr>
          <td>Ins Info Text:</td>
          <td>
            <textarea cols="25" 
              name="ins_info_tx" 
              onKeyUp={countCharacters}
              onFocus={countCharacters}
              onPaste={countCharacters}
              id="ins_info_tx" rows="3"
              maxLength="120"
              value={addContractorVals.ins_info_tx}
              onChange={handleOnChange}>
            </textarea>
            <br/>
            <span id="cRemaining_ins_info_tx">Remaining characters: {120-insCharCount} of 120</span>
          </td>
        </tr>
        <tr>
          <td>Other:</td>
          <td>
            <textarea cols="25" 
              name="other_info_tx" 
              onKeyUp={countCharacters}
              onFocus={countCharacters}
              onPaste={countCharacters}
              id="other_info_tx" rows="3"
              maxLength="255"
              value={addContractorVals.other_info_tx}
              onChange={handleOnChange}>
            </textarea>
            <br/>
            <span id="cRemaining_other_info_tx">Remaining characters: {255-otherCharCount} of 255</span>
          </td>
        </tr>
        </tbody>
      </table>

      <div className="centerAlign">
        <Button label="Add" className="margin10px" onClick={() => addClick()} />
        <Button label="Cancel" className="margin10px" onClick={() => cancelClick()} />
      </div>
    </div>
    <Footer />
  </>)
}

export default AddContractorProfile;