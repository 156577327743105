import {getCall, postCall} from "../../../Services/CommonService";


export async function getDropdownValues(country){

  try{
    const [contractorTypes, superBranchCodes, stateCodes, contactCodes] = await Promise.all([
     getCall("contractorRequestManager/getSpecificContractorTypeCode/" + country),
      getCall("contractorRequestManager/getSuperBranchCodes"),
      getCall(`contractorRequestManager/${country==='CAN'?'getCANprovinces':'getStateCodes'}`),
      getCall("profileMaint/contactCodes/other")
    ])

    console.log('Get Dropdown Values API calls finished');
    console.log(contractorTypes);
    console.log(superBranchCodes);
    console.log(stateCodes);
    console.log(contactCodes);
    const cTypes = contractorTypes.data.map((type)=>({
      name: type.cntrctrTypeDs, 
      value: type.cntrctrTypeCd
    }));
    const bCodes = superBranchCodes.data.map((code)=>({
      name: code.superBranchCd + " - " + code.branchNm, 
      value: code.superBranchCd
    }));
    const sCodes = stateCodes.data.map((code)=>({
      name: country==='CAN' ? code.provinceAbbrCode : code.stateabbrCode + " - " + code.stateName, 
      value: country==='CAN' ? code.provinceAbbrCode : code.stateabbrCode
    }));
    let cCodes = contactCodes.data.map((code)=>({
      name: code.cntctCdDs, 
      value: code.cntctCd
    }));

    return {
      contractorTypes: cTypes,
      superBranchCodes: bCodes,
      stateCodes: sCodes,
      contactCodes: cCodes
    }
  }catch(error){
    console.log("Error calling Get Dropdown Values APIs");
    console.log(error);
    return {
      contractorTypes: [],
      superBranchCodes: [],
      stateCodes: [],
      contactCodes: []
    }
  }
}

export async function isContractorAvailable(contractorLoginId){
  
  try{
  const cntrctrNbResponse = await getCall(
    `addContractorProfile/isContractorAvailable?userId=${contractorLoginId}`
  )

    console.log('cntrctrNbResponse finished: ', cntrctrNbResponse);
    if(cntrctrNbResponse.status === 200) return 'Contractor Login ID already exists. Enter another one.<br/>'
    else return ''
  }catch(err){
    console.log('cntrctrNbResponse error: ', err);
    return '';
  }
}
// effectiveDate, expirDate, busLcnsExpirDate, insExpirDate
export async function persistData(addContractorVals, dateO, priceStructureSets, country, user){
  debugger
  try{
    // Get the Contractor Number
    const cntrctrNbResponse = await getCall(
      "addContractorProfile/getCntrctrSqncNb");
      
    console.log('cntrctrNbResponse call finished');
    console.log(cntrctrNbResponse);
    const cntrctrNb = cntrctrNbResponse.data;

    // Get the contact codes
    const contactCodesResponse = await getCall(
      "profileMaint/contactCodes");

      console.log('contactCodesResponse call finished');
      console.log(contactCodesResponse);
      const contactCodes = contactCodesResponse.data;

    // Body for the personal Detail call
    const personalDetail = {
      cntrctrNb: cntrctrNb,
      effDt: formatDate(dateO.effectiveDate),
      expirDt: formatDate(dateO.expirDate),
      mngtBcLoc: addContractorVals.busCenterLocation,
      cntrctrBusNm: addContractorVals.contractorName,
      addrLine1Tx: addContractorVals.address,
      cityNm: addContractorVals.city,
      stAbbrCd: addContractorVals.state,
      zipCd: addContractorVals.zip,
      emailAddrTx: addContractorVals.email,
      taxNb: addContractorVals.taxId,
      prmryCntctNm: addContractorVals.primaryContact,
      scndryCntctNm: addContractorVals.secondaryContact,
      insInfoTx: addContractorVals.ins_info_tx,
      busLcnsExpirDt: formatDate(dateO.busLcnsExpirDate),
      insInfoExpirDt: formatDate(dateO.insExpirDate),
      lstUpdtUserIdCd: user,
      othrInfoTx: addContractorVals.other_info_tx,
      cntryCd: country,
      cntrctrTypeCd: Number(addContractorVals.contractorTypeCode),
      alrtPrfrncCd: addContractorVals.alertPref,
      rdnInd: Number(addContractorVals.rdnInd),
      gsdbCd: addContractorVals.gsdbCode
    }
    console.log('personalDetail', personalDetail);
    
    // Body for the contractor ID call
    const cntrctrIdRequest = {
      cntrctrNb: cntrctrNb,
      cntrctrUserIdCd: addContractorVals.contractorLoginId,
      lstUpdtUserId: user
    }
    console.log('cntrctrIdRequest', cntrctrIdRequest);

    // Filter the Price Structures
    const filteredSets = priceStructureSets.filter(set => set.clearPc !== 'c');
    // Create the Price structure body and calls
    const cntrctrFeeRequests = filteredSets.map(async (set, index) => {
      const body = {
        cntrctrNb: cntrctrNb,
        prcStrctNb: Number(index),
        prcStrctTx: set.pcName,
        feeAm: Number(set.selfHelp),
        vlntrFeeAm: Number(set.volContractor),
        vlntrTodlrFeeAm: Number(set.dealerVolContractorFee),
        cntngntIn: set.contingency,
        dlvryToAuctIn: set.delToAuct,
        gvwTypeCd: set.gvw,
        prcStrctDelCd: " ",
        lstUpdtUserId: user,
        clsFeeAm: Number(set.closeFee)
      }
      console.log('Contractor fee body: ', body)
      return postCall("addContractorProfile/insertContractorFeeDetails", body)
    });
      // Create array for the body of the phone requests
      let phoneSqnc = 0;
      let phoneCode = contactCodes.find(code => code.cntctCdDs.trim() === 'Primary Phone');
      let phoneList = [{
        cntctSqncNb: phoneSqnc++,
        cntctCd: phoneCode.cntctCd,
        cntrctrNb: cntrctrNb,
        phnCityAreaCd: addContractorVals.primaryPhoneAreaCode,
        phnNb: addContractorVals.primaryPhoneNum,
        lstUpdtUserId: user
      }];

      if (addContractorVals.afterHoursNum) {
        phoneCode = contactCodes.find(code => code.cntctCdDs.trim() === 'After Hours Phone');
        phoneList.push({
          cntctSqncNb: phoneSqnc++,
          cntctCd: phoneCode.cntctCd,
          cntrctrNb: cntrctrNb,
          phnCityAreaCd: addContractorVals.afterHoursAreaCode,
          phnNb: addContractorVals.afterHoursNum,
          lstUpdtUserId: user
        })
      }

      if (addContractorVals.mobileNum) {
        phoneCode = contactCodes.find(code => code.cntctCdDs.trim() === 'Mobile');
        phoneList.push({
          cntctSqncNb: phoneSqnc++,
          cntctCd: phoneCode.cntctCd,
          cntrctrNb: cntrctrNb,
          phnCityAreaCd: addContractorVals.mobileAreaCode,
          phnNb: addContractorVals.mobileNum,
          lstUpdtUserId: user
        })
      }

      if (addContractorVals.primaryTextNum) {
        phoneCode = contactCodes.find(code => code.cntctCdDs.trim() === "Primary Text Phone");
        phoneList.push({
          cntctSqncNb: phoneSqnc++,
          cntctCd: phoneCode.cntctCd,
          cntrctrNb: cntrctrNb,
          phnCityAreaCd: addContractorVals.primaryTextAreaCode,
          phnNb: addContractorVals.primaryTextNum,
          lstUpdtUserId: user
        })
      }

      if (addContractorVals.addPhone1Type) {
        phoneList.push({
          cntctSqncNb: phoneSqnc++,
          cntctCd: Number(addContractorVals.addPhone1Type),
          cntrctrNb: cntrctrNb,
          phnCityAreaCd: addContractorVals.addPhone1AreaCode,
          phnNb: addContractorVals.addPhone1Num,
          lstUpdtUserId: user
        })
      }
      if (addContractorVals.addPhone2Type) {
        phoneList.push({
          cntctSqncNb: phoneSqnc++,
          cntctCd: Number(addContractorVals.addPhone2Type),
          cntrctrNb: cntrctrNb,
          phnCityAreaCd: addContractorVals.addPhone2AreaCode,
          phnNb: addContractorVals.addPhone1Num,
          lstUpdtUserId: user
        })
      }

      if (addContractorVals.addPhone3Type) {
        phoneList.push({
          cntctSqncNb: phoneSqnc++,
          cntctCd: Number(addContractorVals.addPhone3Type),
          cntrctrNb: cntrctrNb,
          phnCityAreaCd: addContractorVals.addPhone3AreaCode,
          phnNb: addContractorVals.addPhone3Num,
          lstUpdtUserId: user
        })
      }

      console.log('phoneList: ', phoneList)

      // Create the contact calls
      const contactRequests = phoneList.map((body) => {
        console.log('Contact body: ', body)
        return postCall("addContractorProfile/insertContractorNumberDetails", body)
      });

      console.log('contactRequests: ', contactRequests);

      const responses = await Promise.all([
        postCall("addContractorProfile/insertPersonalDetails", personalDetail),
        postCall("addContractorProfile/insertContractorIdDetails", cntrctrIdRequest),
        cntrctrFeeRequests
      ])

      console.log('Add Contractor API calls to insert data finished');
      responses.forEach(response => {
        console.log('Responses:', response);
      });

      return 'Contractor data saved successfully';
    }catch(error){
    console.log("Error persisting add contractor data");
    console.log(error);
    return 'There was an error saving the contractor data';
  }
}

function formatDate(date) {
  // Extract month, day, and year
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear();

  // Return formatted string
  return `${month}/${day}/${year}`;
}
