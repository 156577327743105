//import "./RadioButton.css"

const Checkbox = (props) => {
  return (<>
    <div><fieldset className={"fmc-check " + props.className}>
      <legend>{props.label}</legend>
      {
          props.options.map(option =>
            <label key={option.name}>
              <input type="checkbox" name={props.groupName} value={option.value} key={option.value}/>
              <span key={option.name}>{option.name}</span>
            </label>
          )
      }
    </fieldset></div>
  </>)
}

export default Checkbox;