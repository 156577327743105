import React, {useEffect, useState} from 'react';
import {coverageService} from "../Services/CoverageService";

const AddressComponent = React.memo(({ addressData: personalDetail, onChange }) => {
    const [localAddress, setLocalAddress] = useState(personalDetail);
    const [stateOrProvince, setStateOrProvince] = useState([]);

    useEffect(() => {
        const fetchStateOrProvinces = async () => {
            try {
                const stateOrProviceResponse = await coverageService.getStateOrProvinceByStAbbr(personalDetail.cntryCd);
                setStateOrProvince(stateOrProviceResponse);
            }catch (err) {
                console.error('Failed to fetch coverage with id' + err.message);
            }
        };
        fetchStateOrProvinces();
    }, [personalDetail]);

    const handleFieldChange = (e) => {
        const {name, value} = e.target;
        const messagename = e.target.getAttribute('messagename');
        setLocalAddress((prevAddress) => ({
            ...prevAddress,
            [name]: value,
        }));
        onChange(getDataAttrName(name), value, validateField(getDataAttrName(name), value, messagename));
    };

    const getDataAttrName = (name) => {
        switch (name) {
            case 'addressLine':
                return 'addrLine1Tx';
            case 'stateOrProvCodes':
                return 'stAbbrCd';
            case 'city':
                return 'cityNm';
            case 'email':
                return 'emailAddrTx';
            case 'stateOrZip':
                return 'zipCd';
            default:
                return '';
        }
    };
    const validateField = (name, value, messgeName) => {
        let isValid = true;
        let errors = {};
        if(value === '') {
            errors[name] = messgeName + ' cannot be blank.';
            isValid = false;
        }
        if(isValid) {
            switch (name) {
                case 'stateOrZip':
                    if (!/^[a-zA-Z\s]*$/.test(localAddress.stateOrZip)) {
                        errors[name] = messgeName + 'Invalid State format';
                    };
                    break;
                case 'email':
                    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(localAddress.email) || localAddress.email.length > 50) {
                        errors[name] = messgeName + 'Invalid email address';
                    }
                    break;
                default:
                    break;
            }
        }
        return errors;
    };

    return (
        <>
            <tr>
                <td><label>Address Line:</label></td>
                <td><input
                    type="text"
                    name="addressLine"
                    className="fmc-input"
                    value={localAddress.addressLine}
                    messagename="Address"
                    onChange={handleFieldChange}
                /></td>
            </tr>

            <tr>
                <td><label>State or ZIP:</label></td>
                <td><table><tbody><tr><td style={{border: "none", paddingLeft: "0rem"}}>
                    <select id="stateOrProvCodes" name="stateOrProvCodes" size="1" className="fmc-select"
                            value={localAddress.stateOrProvCodes}
                            onChange={handleFieldChange}>
                        {stateOrProvince.map(sp => (
                            <option value={sp.stAbbrCd} key={sp.stAbbrCd}>{sp.stAbbrCd}</option>
                        ))}
                    </select></td>
                    <td style={{border: "none"}}><input
                        type="text"
                        name="stateOrZip"
                        className="fmc-input"
                        value={localAddress.stateOrZip || ''}
                        messagename="Zip/Postal Code"
                        onChange={handleFieldChange}
                    /></td></tr></tbody></table>
                </td>
            </tr>

            <tr>
                <td><label>City:</label></td>
                <td><input
                    type="text"
                    name="city"
                    className="fmc-input"
                    value={localAddress.city || ''}
                    messagename="City"
                    onChange={handleFieldChange}
                />
                </td>
            </tr>

            <tr>
                <td><label>Email:</label></td>
                <td><input
                    type="email"
                    name="email"
                    className="fmc-input"
                    value={localAddress.email || ''}
                    messagename="Email"
                    onChange={handleFieldChange}
                />
                </td>
            </tr>
        </>
    );
});

export default AddressComponent;
