import swal from "sweetalert";
import {postCall} from "./CommonService";

export const showAlert = (message, icon) => swal('', message, icon);
export const showConfirm = (message) => swal(
    {
        title: '',
        text: message,
        icon: 'info',
        buttons: [{
            text: "Cancel",
            value: false,
            visible: true,
            className: "",
            closeModal: true
        }, {
            text: "OK",
            value: true,
            visible: true,
            className: "",
            closeModal: true
        }]
    }
);

export const saveVcr=()=>{
    let validVcrData = {}
    console.log(validVcrData)
    postCall("SqlMgr/VCR" , validVcrData).then(response=> {
        console.log(response)
        if(response.status === 201){
            console.log(response.data)
            console.log("VCR Data saved!!!")
            return response
        }else{
            throw new Error(response.message);
        }
    }).catch(error => {
        console.error(error)
       return error

    });
}