import React from 'react';
import '@ford/ford-design-system/src/web-components/fmc-forms/fmc-select.js';
import '@ford/ford-design-system/src/web-components/fmc-forms/fmc-option.js';
import './Summary.css';

function Invoicesummary() {



  return (
    <div id='details_tab_cntr'>
      <div className='details_cntr'>



        <div id='invoiceheader' className='center' >
          INVOICE
        </div>

        <div className='invoicepart' >

          <section
            className="fmc-global-attribute-bar fmc-global-attribute-bar--vr-md fmc-px-3 fmc-pb-3-sm fmc-py-3-md fmc-py-3-lg fmc-py-3-xl fds-color__bg--white"
          >
            <div className='center'>
              <table class="fmc-table">
                <thead>
                  <tr>
                    <th>Description </th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>All Inclusive Repo Fee On File</td>
                    <td>$1000000</td>

                  </tr>
                  <tr>
                    <td>Storage(Storage paid receipt required)</td>
                    <td>$1000000</td>
                  </tr>
                  <tr>
                    <td>Towing(Storage paid receipt required)</td>
                    <td>$1000000</td>

                  </tr>
                  <tr>
                    <td> Repair Body(paid receipt required)
				  </td>
                    <td>$1000000</td>

                  </tr>
                  <tr>
                    <td>Repair Mechanical(paid receipt required)</td>
                    <td>$1000000</td>

                  </tr>
                  <tr>
                    <td>Close Fees</td>
                    <td>$1000000</td>

                  </tr>
                  <tr>
                    <td>Other</td>
                    <td>$1000000</td>

                  </tr>
                </tbody>
              </table>
            </div>
          </section>


        </div>

        {/* <Footer/>        */}

      </div>
    </div>




  );
}
export default Invoicesummary;