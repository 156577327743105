import React, {useEffect, useState} from 'react';
import PhoneComponent from './Phone';
import DatePicker from "react-datepicker";
import {profileService} from "../Services/ProfileService";
import _ from "lodash";

const ContactComponent = ({ psnlDetail: personalDetail, errors: errorsIn, onContactChange, onPhoneChange }) => {
    const [formData, setFormData] = useState(personalDetail);
    const [localContacts, setLocalContacts] = useState([]);
    const [errors, setErrors] = useState(errorsIn);
    const [loading, setLoading] = useState(true);
    const [touchedFields, setTouchedFields] = useState({});

    useEffect(() => {
        //retrieveContactsByCntrctrNb
        const fetchContactsByCntrctrNb = async () => {
            try {
                const contactsResp = await profileService.getContractContactsByCntrctrNb(personalDetail.cntrctrNb);
                setLocalContacts(contactsResp.data);
            }catch (e) {
                console.log("no contacts found");
            }finally {
                setLoading(false);
            }
        };
        fetchContactsByCntrctrNb();
    }, [personalDetail]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleBlur = (e) => {
        const { name } = e.target;
        setTouchedFields((prevTouchedFields) => ({ ...prevTouchedFields, [name]: true }));
        validateField(name, formData[name]);
        onContactChange(formData, errors);
    };

    const validateField = (name, value) => {
        let error = '';
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: error,
        }));
    };

    const renderError = (name) => {
        if (touchedFields[name] && errors[name]) {
            return <span className="error">{errors[name]}</span>;
        }
        return null;
    };

    const updatePhone = (phone) => {
        _.forEach(localContacts, cPhone => {
            if(cPhone.cntctSqncNb === phone.cntctSqncNb && cPhone.cntctCd === phone.cntctCd){
                cPhone.phnCityAreaCd = phone.phnCityAreaCd;
                cPhone.phnNb = phone.phnNb;
            }
        });
        onPhoneChange(localContacts, errors);
    };

    if(loading) return <p>Loading...</p>

    return (
        <>
            <tr>
                <td><label>Primary Contact:</label></td>
                <td><input
                    type="text"
                    name="prmryCntctNm"
                    className="fmc-input"
                    value={formData.prmryCntctNm}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {renderError('prmryCntctNm')}
                </td>
            </tr>
            <tr>
                <td><label>Secondary Contact:</label></td>
                <td><input
                    type="text"
                    name="secondContact"
                    className="fmc-input"
                    value={formData.secondContact}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                    {renderError('secondContact')}
                </td>
            </tr>
            <tr>
                <td><label>Business License Expiration Date:</label></td>
                <td>
                    <div style={{minWidth: '12em'}}>
                        <DatePicker className="fmc-input" dateFormat="MM/dd/YYYY" value={formData.busLcnsExpirDt}
                                    id="busLcnsExpirDt" name="busLcnsExpirDt"
                                    onChange={handleChange}
                        />
                    </div>
                    {renderError('busLcnsExpirDt')}
                </td>
            </tr>
            <tr>
                <td><label>Insurance Expiration Date:</label></td>
                <td>
                    <div style={{minWidth: '12em'}}>
                        <DatePicker className="fmc-input" dateFormat="MM/dd/YYYY" value={formData.insInfoExpirDt}
                                    id="insInfoExpirDt" name="insInfoExpirDt"
                                    onChange={handleChange}
                        />
                    </div>
                    {renderError('insInfoExpirDt')}
                </td>
            </tr>
            <tr>
                <td><label>Alert Preference:</label></td>
                <td><select
                    name="alrtPrfrncCd"
                    className="fmc-select"
                    style={{width: '16em'}}
                    value={formData.alrtPrfrncCd}
                    onChange={handleChange}
                    onBlur={handleBlur}
                >
                    <option value="E">Email</option>
                    <option value="T">Text via Phone</option>
                    <option value="B">Both Email and Text</option>
                </select>
                    {renderError('alrtPrfrncCd')}
                </td>
            </tr>
            {localContacts ? _.sortBy(localContacts, ['cntctSqncNb'],['asc']).map((cnt, idx) =>
                (<PhoneComponent key={cnt.cntctSqncNb+"-"+cnt.cntctCd} phoneData={cnt} noLabel={cnt.cntctCd === 6 || cnt.cntctCd === 8 || cnt.cntctCd === 9} onChange={updatePhone}
                />)): <p>Loading Phone...</p>}
        </>
    );
};

export default ContactComponent;
