import {adminUserByUserId, externalUserByUserId, getUserDetailsByUserId} from "../api/user.api";

const authService =  {
    isAdminUser: async (userId) => {
        return adminUserByUserId(userId);
    },
    isExternalUser: async (gsdbCd) => {
        return externalUserByUserId(gsdbCd);
    },
    getUserDetail: async (userId) => {
        return getUserDetailsByUserId(userId);
    },
}

export default authService;

