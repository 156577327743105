import {ENDPOINTS} from "../constants/endpoints";
import axios from "axios";

export function getVechileDisposalInfo(customerAccoutNumber,cobusCode,origBranch){
 
    return axios.get("https://localhost:3030/RepoWebFCNA/"+ENDPOINTS['VECHICLE_DISPOSAL_INFO_BASE_URL']+cobusCode+"/"+origBranch+"/"+customerAccoutNumber);
}
export function CreateVehicleDisposalInfo(insertvehicladisposaldata){
  
  return axios.post("https://localhost:3030/RepoWebFCNA/"+ENDPOINTS['VECHICLE_DISPOSAL_INFO_BASE_URL'],insertvehicladisposaldata);
    
}
export function UpdateVehicleDisposalInfo(customerAccoutNumber,cobusCode,origBranch,updatevehicleDisposaldata1){
  
  return axios.put("https://localhost:3030/RepoWebFCNA/"+ENDPOINTS['VECHICLE_DISPOSAL_INFO_BASE_URL']+cobusCode+"/"+origBranch+"/"+customerAccoutNumber,updatevehicleDisposaldata1);
    
}


export function getAuctionLocationList(countrycode){
  
  return axios.get("https://localhost:3030/RepoWebFCNA/"+ENDPOINTS['GET_AUCTION_LOCATION']+countrycode);
    
}

