import "./OutstandingAssignment.css"
import React, {useEffect, useState} from 'react';
import Header from "../../Components/Header/Header";
import Accordion from "./Accordion/Accordion";
import Footer from "../../Components/Footer/Footer";
import {getUserInfoFromSession} from "../../Services/session.mgmt";
import {AssignmentService} from "../../Services/AssignmentService";

function OutstandingAssignment(props) {

    const userInfo = getUserInfoFromSession();
    const [panelsArr, setPanelsArr] = useState([]);
    const [dispPersPropMsg, setdispPersPropMsg] = useState(false);

    const checkNevada = (datarow, age) => {
        if ((datarow.vehRcvrdStAbbrCd?.toUpperCase() === "NV" && age > 90) ||
            (datarow.vehRcvrdStAbbrCd?.toUpperCase() !== "NV" && age > 70)) {
            setdispPersPropMsg(true);
        }
    }

    const pushAccounts = (summary, activeAccounts, holdAccounts, cancelAccounts, groundedAccounts) => {
        summary.data.forEach((datarow)=>{
            if(datarow.cntrctrActnCd==='A'){
                activeAccounts.accounts.push(datarow);
            }else if(datarow.cntrctrActnCd==='H'){
                holdAccounts.accounts.push(datarow);
            }else if(datarow.cntrctrActnCd==='C'){
                cancelAccounts.accounts.push(datarow);
            }else if(datarow.cntrctrActnCd==='G'){
                groundedAccounts.accounts.push(datarow);
            }
        })
    }

    useEffect(()=>{
        const getAccounts = async() => {
            Promise.all([
                AssignmentService.getPersonalPropertyFUP(userInfo.cntrctrNb),
                AssignmentService.getSummary(userInfo.cntrctrNb)
            ]).then(([personalProperty, summary]) => {
                // No CaseID returned from these APIs. Should the column be removed?
                console.log("Personal Property Data: ", personalProperty.data);
                console.log("Summary Data: ", summary.data);

                let activeAccounts = {name: 'Active', accounts: []};
                let holdAccounts = {name: 'Hold', accounts: []};
                let cancelAccounts = {name: 'Cancel', accounts: []};
                let personalPropertyAccounts = {name: 'Personal Property', accounts: []};
                let groundedAccounts = {name: 'Recently Grounded & Submitted', accounts: []};

                if (Array.isArray(summary.data) && summary.data.length > 0){
                    pushAccounts(summary, activeAccounts, holdAccounts, cancelAccounts, groundedAccounts);
                }
                if (Array.isArray(personalProperty.data) && personalProperty.data.length > 0) {
                    personalProperty.data.forEach((datarow) => {
                        let age = Math.round((new Date() - new Date(datarow.crtnDt)) / (1000 * 60 * 60 * 24));
                        checkNevada(datarow, age)

                        let temprow = {
                            'cntrctrAcptDt': datarow.lstUpdtTs,
                            'lastNm': datarow.lastNm,
                            'custAcctNb': datarow.custAcctNb,
                            'vehMdlYr': datarow.vehMdlYr,
                            'vehColorTx': datarow.vehColorTx,
                            'makeDescTx': datarow.makeDescTx,
                            'mdlDescTx': datarow.mdlDescTx,
                            'vinSerialNb': datarow.vinNb,
                            'caseId': datarow.caseId,
                            'vehRcvrdStAbbrCd': datarow.vehRcvrdStAbbrCd,
                            'age': age,
                            'teamWrkBpiNb': datarow.teamwkrBpiNb
                        }
                        personalPropertyAccounts.accounts.push(temprow);
                        return "";
                    })
                }
                setPanelsArr([activeAccounts, holdAccounts, cancelAccounts, groundedAccounts, personalPropertyAccounts])
            })
            .catch((error) => {
                console.log("Error in fetching data: ", error);
            });
        }
        getAccounts();
    },[userInfo.cntrctrNb]);
    

    return (<>
        <Header heading="Outstanding Assignments Summary"/>
        <br/>
        {dispPersPropMsg && <div style={{color: 'red'}}><b>Personal Property aged. See Personal Property section below.</b></div>}
        <section
            className="fmc-global-attribute-bar fmc-global-attribute-bar--vr-md fmc-px-3 fmc-pb-3-sm fmc-py-3-md fmc-py-3-lg fmc-py-3-xl fds-color__bg--white"
        >
        {panelsArr.length > 0 && <Accordion panels={panelsArr} userInfo={userInfo} />}
        </section>
        <br/>
        <Footer />
    </>);
}

export default OutstandingAssignment;