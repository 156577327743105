import serviceAxiosInstance from "../config/api.service.config";
import {ENDPOINTS} from "../constants/endpoints";

export function adminUserByUserId(userId){
    return serviceAxiosInstance.get(ENDPOINTS['IS_REPO_ADMIN'] + '?userId=' + userId);
}

export function externalUserByUserId(userId){
    return serviceAxiosInstance.get(ENDPOINTS['IS_EXTERNAL_USER'] + '?gsdbCd=' + userId);
}

export function getUserDetailsByUserId(userId){
    return serviceAxiosInstance.get(ENDPOINTS['GET_REPO_USER_DETAIL'] + '?userId=' + userId);
}