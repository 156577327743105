import serviceAxiosInstance from "../config/api.service.config";
import {ENDPOINTS} from "../constants/endpoints";

export function getContractorByUserId(userId){
    return serviceAxiosInstance.get(ENDPOINTS['GET_CONTRACTOR'] + '/' + userId);
}

export function getContractorPersonal(contractorNb){
    return serviceAxiosInstance.get(ENDPOINTS['GET_CONTRACTOR_PERSONAL'] + '/' + contractorNb);
}
export function getContractorFeePriceStruct(contractorNb){
    return serviceAxiosInstance.get(ENDPOINTS['GET_CONTRACTOR_FEE_PRICE_STRUCT'] + '/' + contractorNb);
}
export function getContractorContacts(contractorNb){
    return serviceAxiosInstance.get(ENDPOINTS['GET_CONTRACTOR_CONTACT'] + '/' + contractorNb);
}
export function getContactCodes(){
    return serviceAxiosInstance.get(ENDPOINTS['GET_CONTACT_CODES']);
}
export function getBuzCenters(){
    return serviceAxiosInstance.get(ENDPOINTS['GET_BUZ_CENTER']);
}

export function updatePersonalDetail(personalDetail){
    console.log("updatePersonalDetail: " + JSON.stringify(personalDetail));
    return serviceAxiosInstance.put(ENDPOINTS['UPDATE_PROFILE_PERSONAL_DETAIL'], personalDetail);
}

export function updateProfilePriceStruct(priceStruct){
    return serviceAxiosInstance.put(ENDPOINTS['UPDATE_PROFILE_PRICE_STRUCT'], priceStruct);
}

export function deleteUSAPriceStruct(cntrctrNb, prcStrctNb){
    return serviceAxiosInstance.delete(ENDPOINTS['DELETE_PRICE_STRUCT_USA']+"?cntrctrNb="+cntrctrNb+"&prcStrctNb="+prcStrctNb);
}

export function deleteContactsByCntrctrNb(cntrctrNb){
    return serviceAxiosInstance.delete(ENDPOINTS['DELETE_CONTACTS_BY_CNTRCTR_NB']+"?cntrctrNb="+cntrctrNb);
}

export function deletePriceStructByUpdateD(priceStruct, cntrctrNb){
    return serviceAxiosInstance.put(ENDPOINTS['UPDATE_PRICE_STRUCT_D']
        +"?lstUpdtUserId=" + priceStruct.lstUpdtUserId
        +"&cntrctrNb=" + cntrctrNb
        +"&prcStrctNb=" + priceStruct.prcStrctNb
        +"&effDtStr=" + priceStruct.effDtStr);
}

export function deleteCANPriceStruct(cntrctrNb, prcStrctNb){
    return serviceAxiosInstance.delete(ENDPOINTS['DELETE_PRICE_STRUCT_CAN']+"?cntrctrNb="+cntrctrNb+"&prcStrctNb="+prcStrctNb);
}

export function createPriceStruct(priceStructCntrctrNb){
    return serviceAxiosInstance.post(ENDPOINTS['CREATE_PRICE_STRUCT'], priceStructCntrctrNb);
}

export function createContractorContact(contact){
    return serviceAxiosInstance.post(ENDPOINTS['CREATE_CONTRACTOR_CONTACT'], contact);
}

export function getContractorData(country){
    return serviceAxiosInstance.get(ENDPOINTS['GET_CONTRACTOR_DATA'] + '/'+ country);
}

export function isContractorAvailable(contractorUserId){
    return serviceAxiosInstance.get(ENDPOINTS['IS_CONTRACTOR_AVAILABLE'] + '?userId='+ contractorUserId);
}

export function deleteContractorIdDetails(cntrctrNb){
    return serviceAxiosInstance.delete(ENDPOINTS['DELETE_CONSTRACTOR_ID_DETAILS'] + '?cntrctrNb='+ cntrctrNb);
}

export function createContractorIdDetails(idDetails){
    return serviceAxiosInstance.post(ENDPOINTS['CREATE_CONTRACTOR_ID_DETAILS'], idDetails);
}