import serviceAxiosInstance from "../config/api.service.config";
import {ENDPOINTS} from "../constants/endpoints";
import axios from "axios";

export function getTireConditionCodes(){
    return serviceAxiosInstance.get(ENDPOINTS['GET_TIRE_CONDITION_CODE'] );
}

export function getOverallConditionCodes(){
    return serviceAxiosInstance.get(ENDPOINTS['GET_OVERALL_CONDITION_CODE'] );
}

export function getGeneralConditionCodes(){
    return serviceAxiosInstance.get(ENDPOINTS['GET_GENERAL_CONDITION_CODE'] );
}

export function getInteriorConditionCodes( ){
    return serviceAxiosInstance.get(ENDPOINTS['GET_INTERIOR_CONDITION_CODE'] );
}

export function getGlassConditionCodes( ){
    return serviceAxiosInstance.get(ENDPOINTS['GET_GLASS_CONDITION_CODE']  );
}


export function getMechanicalConditionCodes(){
    return serviceAxiosInstance.get(ENDPOINTS['GET_MECHANICAL_CONDITION_CODE'] );
}

export function getStates() {
    return serviceAxiosInstance.get(ENDPOINTS['GET_STATES_META']);
}

export function getProvinces() {
    return serviceAxiosInstance.get(ENDPOINTS['GET_PROVINCE_META']);
}
export function saveVcr(vcrData) {
    return serviceAxiosInstance.post(ENDPOINTS['SAVE_VCR_DATA'], vcrData);
}
export function getVcrObj() {
    return axios.get('/JsonFiles/vcrObj.json');
}
export function getVehLocObj() {
    return axios.get('/JsonFiles/VehLoc.json');
}