import React, {useEffect, useState} from 'react';
import {profileService} from "../Services/ProfileService";

const PhoneComponent = ({ phoneData: initPhoneData, noLabel: noLabelIn, onChange }) => {
    const [formData, setFormData] = useState(initPhoneData);
    const [errors] = useState({});
    const [phoneCodes, setPhoneCodes] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchAdditionalPhoneLabel = async () => {
            try {
                const phoneCodesResp = await profileService.getPhoneCodes();
                setPhoneCodes(phoneCodesResp.data);
            }catch (e) {
                console.log("can't get phone codes");
            }finally {
                setLoading(false);
            }
        };
        fetchAdditionalPhoneLabel();
        console.log("initPhoneData === " + JSON.stringify(formData));
        onChange(formData, errors);
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const newPhone = {
            ...formData,
            [name]: value,
        };
        setFormData(newPhone);
        console.log("phone updated with: " + JSON.stringify(formData));
        onChange(newPhone);
    };

    const getLabel = (cntctCd) => {
        let nameLabel = '';
        switch (cntctCd) {
            case 1:
                nameLabel = "Primary Phone Number:";
                break;
            case 2:
                nameLabel = "Fax Number:";
                break;
            case 7:
                nameLabel = "After Hours Phone:";
                break;
            case 4:
                nameLabel = "Mobile Phone:";
                break;
            case 5:
                nameLabel = "Primary Text Phone:";
                break;
            case 6:
                nameLabel = "Additional Phones:";
                break;
            default:
                break;
        }
        return nameLabel;
    }

    if(loading) return <p>Loading...</p>;

    return (
        <tr>
            <td style={{width: '36%'}}><label>{getLabel(initPhoneData.cntctCd)}</label></td>
            <td>
                <table>
                    <tbody>
                        <tr>
                            {noLabelIn?
                            <td style={{border: "none", paddingLeft: "0rem"}}>
                                <select id="phoneAdditionalLabel" name="phoneAdditionalLabel" value={formData.cntctCd}
                                    className="fmc-select" onChange={handleInputChange}>
                                    {phoneCodes?.map((phoneCode) => (
                                        <option key={phoneCode.cntctCd} value={phoneCode.cntctCd}>
                                            {phoneCode.cntctCdDs}
                                        </option>
                                    ))}
                                </select>
                            </td>:''
                            }
                            <td style={{border: "none", paddingLeft: "0rem"}}>
                                <input
                                type="text"
                                name="phnCityAreaCd"
                                className="fmc-input"
                                style={{width: '5em'}}
                                placeholder="Area Code"
                                value={formData.phnCityAreaCd || ''}
                                onChange={handleInputChange}
                                maxLength="3"/>
                            </td>
                            <td style={{border: "none"}}>
                                <input
                                type="text"
                                name="phnNb"
                                className="fmc-input"
                                style={{width: '12em'}}
                                placeholder="Phone Number"
                                value={formData.phnNb || ''}
                                onChange={handleInputChange}
                                maxLength="7"/>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
    );
};

export default PhoneComponent;
