import "./PersonalProperty.css"
import React, {useEffect, useState} from 'react';

import {showAlert, showConfirm} from "../../Services/CommonFunctions";
import AssignmentDetailsHeader from "../common/AssignmentDetailsHeader";
import {GroundingService} from "../../Services/GroundingService";
import {getUserInfoFromSession} from "../../Services/session.mgmt";
import {useNavigate} from "react-router-dom";

function PersonalProperty(props) {
    const navigate = useNavigate();
    const { assignmentStatus } = props;
    let teamWrkBpiNb =Number(props.bpiNo)
    const userInfo = getUserInfoFromSession()
    const [formData, setFormData] = useState({
        ppIndicator: '',
        ppReturned: '',
        item01: '',
        item02: '',
        item03: '',
        item04: '',
        item05: '',
        item06: '',
        item07: '',
        item08: '',
        item09: '',
        item10: '',
        item11: '',
        item12: '',
        item13: '',
        item14: '',
        item15: '',
        wherePropertyStored: '',
        storingContractorName: '',
        customerNameDate: ''
    })
    const clearFormData =()=>{
        setFormData({
            ppIndicator: '',
            ppReturned: '',
            item01: '',
            item02: '',
            item03: '',
            item04: '',
            item05: '',
            item06: '',
            item07: '',
            item08: '',
            item09: '',
            item10: '',
            item11: '',
            item12: '',
            item13: '',
            item14: '',
            item15: '',
            wherePropertyStored: '',
            storingContractorName: '',
            customerNameDate: ''
        })
    }


    useEffect(() => {

    }, []);

async function savePersonalPropertyDetails(formData) {
    try {
        for (let i = 1; i < 15; i++) {
            let key = "";
            let value = "";
            if (i < 10) {
                key = 'item0' + i.toString()
                value = formData[key].toString()
                console.log(value)
            } else {
                key = 'item' + i.toString()
                value = formData[key].toString()
                console.log(value)
            }
            if (value !== "") {
                let ppDetails = {
                    "itmNb": i,
                    "itmNm": value,
                    "lstUpdtUserId": userInfo.userid,
                    "teamWrkBpiNb": teamWrkBpiNb
                }
                const response = await GroundingService.savePPDetails(ppDetails)
                console.log( response)
            }
        }
        showAlert("Personal Property Details Saved successfully!!" , "success")
        clearFormData()
        return true

    } catch (err) {
        console.log(err)
    }
}

    const onChangeHandler =(event)=>{
        setFormData(()=>({
            ...formData,
            [event.target.name]:event.target.value
        }))
    }

    const validatePersonalProperty = async (formData, errorMessage) => {
        if (formData.ppReturned !== '') {
            //we have disposed or returned it... clear the rest
            if ((isPropertyDetailed() || isStorageDetailed()) && 
            await showConfirm("all property data will be cleared, proceed?")) {
                clearPropertyDetail();
            } else {
                errorMessage +="Please delete the personal property that has been inventoried and the storage information if you have disposed or returned it.\n";
            }
        } else if (!isPropertyDetailed()) {
            //we have not returned or disposed the property...
            errorMessage +="Inidicate the property that was recovered.\n"
        }
        else if (!isStorageDetailed()) {
            errorMessage +="Inidicate where the property is stored & Name of the contractor.\n"
        }
        return errorMessage;
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        console.table(formData);
        //handle submit from repoweb war
        let errorMessage =""
        if (formData.ppIndicator ==='') {
            errorMessage +="Indicate the personal property status.\n"
        }else if (formData.ppIndicator === "Y"){
            //we have answered the property question
            //we have property
            errorMessage += await validatePersonalProperty(formData, errorMessage);
        } else if ((isPropertyDetailed() || isStorageDetailed()) && 
        await showConfirm("all property data will be cleared, proceed?")) {
            clearPropertyDetail();
            clearDisposalDetail();
        } else {
            errorMessage +="Please delete the personal property that has been inventoried and the storage information if you have disposed or returned it.\n"
        }

        if (errorMessage !== "") {
            await showAlert(errorMessage, "warning");
        } else if(count >1 && formData.ppReturned ===''){
            console.log(formData)
            if(await savePersonalPropertyDetails(formData)) {
                navigate(`/outstandingsummary/${teamWrkBpiNb}`);
            }
        }else{
            await showAlert("Personal Property Information Saved Successfully!!", "success")
            clearFormData()
            navigate(`/outstandingsummary/${teamWrkBpiNb}`);
        }
    }
    let count=0;
    function isPropertyDetailed() {
        for(let i=1 ; i<15; i++){
            let key = "";
            let value = "";
            if(i<10){
                    key = 'item0' + i.toString()
                    value = formData[key].toString()
                console.log(value)
            }else{
                key = 'item' + i.toString()
                value =formData[key].toString()
                console.log(value)
            }
            if(value !== ""){
                count++
            }
        }
        return count > 1;
    }

    function isStorageDetailed() {
        return (
            formData.wherePropertyStored !=='' && formData.storingContractorName !==''
        )

    }

    const resetFormData =async () => {
        if (await showConfirm("all property data will be cleared, proceed?")) {
            clearPropertyDetail();
        }
    }

    function clearDisposalDetail() {
        formData.ppReturned= '';
    }
    function clearPropertyDetail() {
        console.log("enteredinto clearPropertyDetails")
        clearFormData()
        return true;
    }

    return(
        <>
            <br/>
            <AssignmentDetailsHeader bpiNo={teamWrkBpiNb}/>
            <br/>
            <br/>
            <form id="personalProperty-form" onSubmit={handleSubmit}>
                <fieldset disabled={assignmentStatus === 'H' ? true:false}>
                <div style={{marginInline: 2 + 'em'}}>
                    <section className="fds-display--flex">
                        Personal property in the vehicle when recovered.
                        <div>
                            <fieldset className="fmc-check fmc-check--horizontal" style={{paddingLeft: 2 + 'em'}}>
                                <label>
                                    <input type="radio" name="ppIndicator" value="Y" onChange={onChangeHandler} checked={formData.ppIndicator === 'Y'}/>
                                    <span>Yes</span>
                                </label>
                                <label>
                                    <input type="radio" name="ppIndicator" value="N" onChange={onChangeHandler} checked={formData.ppIndicator === 'N'}/>
                                    <span>No</span>
                                </label>
                            </fieldset>
                        </div>
                    </section>
                    If "Yes", complete the following form, itemize the property(expect in CA), and when returned to
                    Customer
                    have him sign in space provided.
                    <hr/>
                    <section style={{padding: 1 + 'em'}}>
                        <div>
                            <fieldset className="fmc-check fmc-check--horizontal">
                                <label>
                                    <input type="radio" name="ppReturned"
                                           value="returned" onChange={onChangeHandler} checked={formData.ppReturned === 'returned'}/>
                                    <span>Personal property returned.</span>
                                </label>
                                <label>
                                    <input type="radio" name="ppReturned"
                                           value="disposed" onChange={onChangeHandler} checked={formData.ppReturned === 'disposed'}/>
                                    <span>Personal property disposed.</span>
                                </label>
                            </fieldset>
                        </div>
                    </section>
                    <hr/>
                    <br/>
                    <section
                        className="fmc-px-3 fmc-pb-3-sm fmc-py-3-md fmc-py-3-lg fmc-py-3-xl fds-color__bg--white">

                        <div className="fds-layout-gri p-0">
                            <div className="fds-layout-grid__inner p-0 ">
                                <input className="fmc-input fds-layout-grid__cell--span-4"
                                       style={{width: 15 + 'em', margin: 1 + 'em'}}
                                       type="text" placeholder="1" name="item01" value={formData.item01 } maxLength="50"
                                       onChange={onChangeHandler}/>

                                <input className="fmc-input fds-layout-grid__cell--span-4"
                                       style={{width: 15 + 'em', margin: 1 + 'em'}}
                                       type="text" placeholder="2" name="item02" value={formData.item02} maxLength="50"
                                       onChange={onChangeHandler}/>

                                <input className="fmc-input fds-layout-grid__cell--span-4"
                                       style={{width: 15 + 'em', margin: 1 + 'em'}}
                                       type="text" placeholder="3" name="item03" value={formData.item03} maxLength="50"
                                       onChange={onChangeHandler}/>
                            </div>
                            <div className="fds-layout-grid__inner p-0 ">
                                <input className="fmc-input fds-layout-grid__cell--span-4"
                                       style={{width: 15 + 'em', margin: 1 + 'em'}}
                                       type="text" placeholder="4" name="item04" value={formData.item04} maxLength="50"
                                       onChange={onChangeHandler}/>
                                <input className="fmc-input fds-layout-grid__cell--span-4"
                                       style={{width: 15 + 'em', margin: 1 + 'em'}}
                                       type="text" placeholder="5" name="item05" value={formData.item05} maxLength="50"
                                       onChange={onChangeHandler}/>
                                <input className="fmc-input fds-layout-grid__cell--span-4"
                                       style={{width: 15 + 'em', margin: 1 + 'em'}}
                                       type="text" placeholder="6" name="item06" value={formData.item06} maxLength="50"
                                       onChange={onChangeHandler}/>
                            </div>
                            <div className="fds-layout-grid__inner p-0 ">
                                <input className="fmc-input fds-layout-grid__cell--span-4"
                                       style={{width: 15 + 'em', margin: 1 + 'em'}}
                                       type="text" placeholder="7" name="item07" value={formData.item07} maxLength="50"
                                       onChange={onChangeHandler}/>
                                <input className="fmc-input fds-layout-grid__cell--span-4"
                                       style={{width: 15 + 'em', margin: 1 + 'em'}}
                                       type="text" placeholder="8" name="item08" value={formData.item08} maxLength="50"
                                       onChange={onChangeHandler}/>
                                <input className="fmc-input fds-layout-grid__cell--span-4"
                                       style={{width: 15 + 'em', margin: 1 + 'em'}}
                                       type="text" placeholder="9" name="item09" value={formData.item09} maxLength="50"
                                       onChange={onChangeHandler}/>
                            </div>
                            <div className="fds-layout-grid__inner p-0 ">
                                <input className="fmc-input fds-layout-grid__cell--span-4"
                                       style={{width: 15 + 'em', margin: 1 + 'em'}}
                                       type="text" placeholder="10" name="item10" value={formData.item10} maxLength="50"
                                       onChange={onChangeHandler}/>
                                <input className="fmc-input fds-layout-grid__cell--span-4"
                                       style={{width: 15 + 'em', margin: 1 + 'em'}}
                                       type="text" placeholder="11" name="item11" value={formData.item11} maxLength="50"
                                       onChange={onChangeHandler}/>
                                <input className="fmc-input fds-layout-grid__cell--span-4"
                                       style={{width: 15 + 'em', margin: 1 + 'em'}}
                                       type="text" placeholder="12" name="item12" value={formData.item12} maxLength="50"
                                       onChange={onChangeHandler}/>
                            </div>
                            <div className="fds-layout-grid__inner p-0 ">
                                <input className="fmc-input fds-layout-grid__cell--span-4"
                                       style={{width: 15 + 'em', margin: 1 + 'em'}}
                                       type="text" placeholder="13" name="item13" value={formData.item13} maxLength="50"
                                       onChange={onChangeHandler}/>
                                <input className="fmc-input fds-layout-grid__cell--span-4"
                                       style={{width: 15 + 'em', margin: 1 + 'em'}}
                                       type="text" placeholder="14" name="item14" value={formData.item14} maxLength="50"
                                       onChange={onChangeHandler}/>
                                <input className="fmc-input fds-layout-grid__cell--span-4"
                                       style={{width: 15 + 'em', margin: 1 + 'em'}}
                                       type="text" placeholder="15" name="item15" value={formData.item15} maxLength="50"
                                       onChange={onChangeHandler}/>
                            </div>
                        </div>

                    </section>
                    <hr/>
                    <div className="row">
                        <div className="column form-group">
                            <label>Where property stored</label>
                            <input className="fmc-input fds-layout-grid__cell--span-4"
                                   style={{width: 15 + 'em', marginTop: 0.5 + 'em'}}
                                   type="text" placeholder="" name="wherePropertyStored"
                                   value={formData.wherePropertyStored} onChange={onChangeHandler}/>
                        </div>
                        <div className="column form-group">
                            <label>Name of storing contractor</label>
                            <input className="fmc-input fds-layout-grid__cell--span-4"
                                   style={{width: 15 + 'em', marginTop: 0.5 + 'em'}}
                                   type="text" placeholder="" name="storingContractorName"
                                   value={formData.storingContractorName} onChange={onChangeHandler}/>
                        </div>
                        <div className="column form-group">
                            <label>I acknowledge return of property listed.</label>
                            <input className="fds-layout-grid__cell--span-4 sign"
                                   style={{width: 18 + 'em', marginTop: 2.5 + 'em'}}
                                   type="text" placeholder="" name="customerNameDate"
                                   value={formData.customerNameDate} onChange={onChangeHandler}/>
                            <label htmlFor="inputField">Customer's Name and Date</label>
                        </div>
                    </div>


                    <br/>
                    <p className="fds-align--center">PERSONAL PROPERTY & TRUST RECEIPT</p>
                    <section style={{padding: 2 + 'em'}}>
                        <div className="fds-align--center">
                            <button className="fmc-button"  type="submit" style={{marginInline: 2 + 'em'}}>Continue</button>
                            <button className="fmc-button" type="button" style={{marginInline: 2 + 'em'}} onClick={resetFormData}>Reset</button>
                        </div>
                    </section>
                </div>
                </fieldset>
            </form>
            <br/>

        </>
    )
}

export default PersonalProperty;