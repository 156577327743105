export  const  PINNCALE_LOG_OBJ={
 
  "systemName": "WORKFLOW",
  "enteredBranchCode": "",
  "customerServiceFollowUpDate": "",
  "customerServiceFollowUpTime": "",
  "comment": "",
  "isPermanent": true,
  "thirdPartySequenceNumber": null,
  "contactThirdPartySequenceNumber": null,
  "createdBy": "",
  "createdTimeStamp": "2024-06-14 17:20:29",
  "typeCode": 4,
  "actionCode": 137,
  "partyCode": 6,
  "resultsCode": 466,
  "reasonCode": 370,
  "rootCause": 78,
  "categoryCode": 52,
  "receivableAccountNumber": 58772772,
  "coBusCd": 1,
  "promiseToPayDate1": null,
  "promiseToPayAmount1": null,
  "promiseToPayDate2": null,
  "promiseToPayAmount2": null
}
 

export  const VEHICLE_DISPOSAL_OBJ =
  {
    custAcctNb: 356744684,
    coBusCd: 7201,
    discorigBranch: "AC",
    teamworkBPI: 1479,
    vin: "1FA6P0H76E5378637",
    repoTrmDt: "2024-09-03T14:08:26.404Z", // vcr 
    mlDrvnNb: 0, //vcr
    dispoRsnCd: 1, // constant
    crtDt: "2024-09-03T14:08:26.404Z", //curTime
    legalSaleDt: "2024-09-03T14:08:26.404Z",
    lgclDelIn: null, // sending as null
    availPkDt: "2024-09-03T14:08:26.404Z",  //repoTrmDt
    lastUpdatedUserIdentificationCode: "dravich8",
    lastUpdatedTimestamp: "2024-09-03T14:08:26.404Z"
  }


  //  mlDrvnNb