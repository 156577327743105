const reCurrency1 = /^\d+$/
const reCurrency2 = /^\.\d$/
const reCurrency3 = /^\.\d\d$/
const reCurrency4 = /^\d+\.$/
const reCurrency5 = /^\d+\.\d$/
const reCurrency6 = /^\d+\.\d\d$/
const reInteger = /^\d+$/
const daysInMonth = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

export const validatevcr = (VcrFromData, VehLocData) => {
    let errorMessage = '';
    let repoDate = new Date();

    const vcr_dorm = VcrFromData.rcvrDt.substring(5, 7);
    const vcr_dord = VcrFromData.rcvrDt.substring(8, 10);
    const vcr_dory = VcrFromData.rcvrDt.substring(0, 4);

    const vcr_preparedate_month = VcrFromData.prepDt.substring(5, 7);
    const vcr_preparedate_day = VcrFromData.prepDt.substring(8, 10);
    const vcr_preparedate_year = VcrFromData.prepDt.substring(0, 4);

    errorMessage += validateDate(vcr_dorm, vcr_dord, vcr_dory, repoDate);
    errorMessage += validatePreparedBy(VcrFromData.prepByTx);
    errorMessage += validateDate(vcr_preparedate_month, vcr_preparedate_day, vcr_preparedate_year, repoDate, vcr_dory, vcr_dorm, vcr_dord);
    errorMessage += validateMileage(VcrFromData.vehMlgeNb);
    errorMessage += validateCondition(VcrFromData);
    errorMessage += validateDamageEstimate(VcrFromData.dmgEstAm);
    errorMessage += validateRecoveryDetails(VcrFromData);
    errorMessage += validateVehicleLocation(VehLocData);

    if (errorMessage !== "") {
        let msg = "The form was not submitted because the following corrections are required.\n";
        msg += "***********************************\n\n";
        msg += errorMessage;
        return msg;
    } else {
        return null;
    }
};

const validateDate = (month, day, year, repoDate, vcr_dory, vcr_dorm, vcr_dord) => {
    let errorMessage = '';
    if (!isDate(month, day, year)) {
        errorMessage += "Date is invalid.\n";
        repoDate = null;
    } else {
        let enteredDate = new Date(year, month - 1, day);
        if (repoDate == null || repoDate.getTime() !== enteredDate.getTime()) {
            repoDate = enteredDate;
            let daysDiff = Math.ceil((new Date().getTime() - repoDate.getTime()) / (1000 * 60 * 60 * 24));
            if (daysDiff > 29) errorMessage += "Date exceeds 29 days. Please check and re-enter.\n";
        }
    }
    return errorMessage;
};

const validatePreparedBy = (prepByTx) => {
    return prepByTx.isEmpty || prepByTx === '' ? "Enter a name in the 'Prepared By' field.\n" : '';
};

const validateMileage = (vehMlgeNb) => {
    let errorMessage = '';
    if (vehMlgeNb === "" || vehMlgeNb.isEmpty) {
        errorMessage += "Indicate mileage.\n";
    } else {
        if (vehMlgeNb < 0) {
            errorMessage += "Mileage must be numeric and positive.\n";
        }
    }
    return errorMessage;
};

const validateCondition = (VcrFromData) => {
    let errorMessage = '';
    const conditions = [
        { field: VcrFromData.genCndtnCd, message: "Indicate the general condition\n" },
        { field: VcrFromData.mechCndtnCd, message: "Indicate the mechanical condition\n" },
        { field: VcrFromData.glsCndtnCd, message: "Indicate the glass condition\n" },
        { field: VcrFromData.lfTireCndtnCd, message: "Enter the condition of the 'Left Front' tire.\n" },
        { field: VcrFromData.lrTireCndtnCd, message: "Enter the condition of the 'Left Rear' tire.\n" },
        { field: VcrFromData.rfTireCndtnCd, message: "Enter the condition of the 'Right Front' tire.\n" },
        { field: VcrFromData.rrTireCndtnCd, message: "Enter the condition of the 'Right Rear' tire.\n" },
        { field: VcrFromData.spTireCndtnCd, message: "Enter the condition of the 'Spare' tire.\n" },
        { field: VcrFromData.intCndtnCd, message: "Indicate the condition of the interior.\n" },
        { field: VcrFromData.ovrlBodyCndtnCd, message: "Enter the condition of the 'body'.\n" },
        { field: VcrFromData.ovrlPntCndtnCd, message: "Enter the condition of the 'paint'.\n" },
        { field: VcrFromData.ovrlBmprCndtnCd, message: "Enter the condition of the 'bumpers'.\n" }
    ];

    conditions.forEach(condition => {
        if (condition.field.isEmpty || condition.field === '') {
            errorMessage += condition.message;
        }
    });

    if (VcrFromData.glsCndtnCd === 3 && (VcrFromData.glsBrknDs === "")) {
        errorMessage += "Indicate location of broken glass.\n";
    }
    if (VcrFromData.glsCndtnCd === 2 && (VcrFromData.glsBrknDs === "")) {
        errorMessage += "Indicate location of scratched glass.\n";
    }
    if (VcrFromData.intCndtnCd === 2 && (VcrFromData.intExplDs.isEmpty || VcrFromData.intExplDs === '')) {
        errorMessage += "Explain the rough interior.\n";
    } else {
        VcrFromData.intExplDs = "NA";
    }

    return errorMessage;
};

const validateDamageEstimate = (dmgEstAm) => {
    let errorMessage = '';
    if (dmgEstAm.isEmpty || dmgEstAm === '') {
        errorMessage += "Enter an estimate of the damages.\n";
    } else {
        if (!isCurrency(dmgEstAm)) {
            errorMessage += "Damage Estimate is not a valid currency format.\n";
        }
    }
    return errorMessage;
};

const validateRecoveryDetails = (VcrFromData) => {
    let errorMessage = '';
    errorMessage += validateField(VcrFromData.vehRcvrdFromNm, "Indicate who the vehicle was recovered from.\n");
    errorMessage += validateOtherRecoveryDetails(VcrFromData);
    errorMessage += validateField(VcrFromData.vehRcvrdAddrTx, "Enter the recovered from address.\n");
    errorMessage += validateField(VcrFromData.vehRcvrdCityNm, "Enter the recovered from city.\n");
    errorMessage += validateField(VcrFromData.vehRcvrdStAbbrCd, "Enter the recovered from state.\n");
    errorMessage += validateZipCode(VcrFromData.vehRcvrdZipCd, "Recovered from zip code is invalid.\n");
    errorMessage += validateCustomerRelease(VcrFromData);
    errorMessage += validateOtherComments(VcrFromData.othCmtDs);
    return errorMessage;
};

const validateField = (field, message) => {
    return field === '' || field.isEmpty ? message : '';
};

const validateOtherRecoveryDetails = (VcrFromData) => {
    let errorMessage = '';
    if (VcrFromData.vehRcvrdFromNm === "OTHER" && (VcrFromData.vehRcvOthrSpcfy.isEmpty || VcrFromData.vehRcvOthrSpcfy === '')) {
        errorMessage += "Specify who (Other) the vehicle was recovered from.\n";
    }
    return errorMessage;
};


const validateCustomerRelease = (VcrFromData) => {
    let errorMessage = '';
    if (VcrFromData.cstRlsIn.isEmpty || VcrFromData.cstRlsIn === '') {
        errorMessage += "Indicate if the release was signed by the customer.\n";
    } else if (VcrFromData.cstRlsIn === "N") {
        errorMessage += validateField(VcrFromData.cstRlsDs, "Explain why the release was not signed by the customer.\n");
        errorMessage += validateAuthoritiesNotified(VcrFromData);
    }
    return errorMessage;
};

const validateAuthoritiesNotified = (VcrFromData) => {
    let errorMessage = '';
    if (VcrFromData.athNtfyIn.isEmpty || VcrFromData.athNtfyIn === '') {
        errorMessage += "Indicate whether the proper authorities were notified.\n";
    } else if (VcrFromData.athNtfyIn === "Y") {
        errorMessage += validateField(VcrFromData.athNtfyNm, "Enter the authorities notified name.\n");
        errorMessage += validateField(VcrFromData.athNtfyAddrTx, "Enter the authorities notified address.\n");
        errorMessage += validateField(VcrFromData.athNtfyCityNm, "Enter the authorities notified city.\n");
        errorMessage += validateField(VcrFromData.athNtfyStAbbrCd, "Enter the authorities notified state.\n");
        errorMessage += validateZipCode(VcrFromData.athNtfyZipCd, "Authorities notified zip code is invalid.\n");
    } else {
        errorMessage += validateOtherComments(VcrFromData.othCmtDs);
    }
    return errorMessage;
};

const validateOtherComments = (othCmtDs) => {
    let errorMessage = '';
    if (othCmtDs.isEmpty || othCmtDs === '') {
        errorMessage += "Please indicate, in 'Other Comments', why the proper authorities were not notified.\n";
    } else if (othCmtDs.length > 255) {
        errorMessage += "'Other Comments' is too long, it can only be 255 characters. Right now it is " + othCmtDs.length + " characters.\n";
    }
    return errorMessage;
};

const validateVehicleLocation = (VehLocData) => {
    let errorMessage = '';
    errorMessage += validateLocation(VehLocData.vehLoc);
    errorMessage += validateAuction(VehLocData.vehLoc, VehLocData.auctNm);
    errorMessage += validateContractorDealerOther(VehLocData);
    return errorMessage;
};

const validateLocation = (vehLoc) => {
    return vehLoc.isEmpty || vehLoc === '' ? "Indicate the location of the vehicle.\n" : '';
};

const validateAuction = (vehLoc, auctNm) => {
    return vehLoc === "AUCTION" && (auctNm.isEmpty || auctNm === '') ? "Select the auction name.\n" : '';
};

const validateContractorDealerOther = (VehLocData) => {
    let errorMessage = '';
    if (VehLocData.vehLoc === "CONTRACTOR" || VehLocData.vehLoc === "DEALER" || VehLocData.vehLoc === "OTHER") {
        errorMessage += validateField(VehLocData.vehLocNm, "Vehicle Located At NAME is required.\n");
        errorMessage += validateField(VehLocData.addrLine1Tx, "Vehicle Located At ADDRESS LINE 1 is required.\n");
        errorMessage += validateField(VehLocData.cityNm, "Vehicle Located At CITY is required.\n");
        errorMessage += validateField(VehLocData.stAbbrCd, "Vehicle Located At STATE is required.\n");
        errorMessage += validateZipCode(VehLocData.zipCd, "Vehicle Located At ZIP CODE is required.\n");
        errorMessage += validatePhoneNumber(VehLocData.phnCityAreaCd, VehLocData.phnNb);
    }
    return errorMessage;
};

const validateZipCode = (zipCd, invalidMessage) => {
    if (zipCd === '' || zipCd.isEmpty) {
        return "Enter the recovered from zip code.\n";
    } else if (!isZIPCode(zipCd)) {
        return invalidMessage;
    }
    return '';
};

const validatePhoneNumber = (phnCityAreaCd, phnNb) => {
    return !checkPhoneArea(phnCityAreaCd) || !checkPhone(phnNb) ? "Vehicle Located At Phone Number must be a 10 digit number.\n" : '';
};

function checkPhoneArea(phoneAreaField){
    let bReturn=true;
    let rePhoneAreaNumbers = /^(\d)(\d)(\d)+$/
    if(!(phoneAreaField.isEmpty || phoneAreaField === '')){
        if (!rePhoneAreaNumbers.test(phoneAreaField)){
            bReturn=false;
        }
    }
    return bReturn;
}

function checkPhone(phoneField){
    let bReturn=true;
    let rePhoneNumbers = /^(\d)(\d)(\d)(\d)(\d)(\d)(\d)+$/
    if (!(phoneField.isEmpty || phoneField === '')){
        if (!rePhoneNumbers.test(phoneField)){
            bReturn=false;
        }
    }
    return bReturn;
}
function isIntegerInRange (s, a, b)
{
    let num = parseInt (s);
    return ((num >= a) && (num <= b));
}
function isCurrency (s)
{
    return ( reCurrency1.test(s) || reCurrency2.test(s) || reCurrency3.test(s) || reCurrency4.test(s) || reCurrency5.test(s) || reCurrency6.test(s))
}
function isZIPCode (s)
{
    return (isInteger(s) && ( (s.length === 5) || (s.length === 9) ) )
}
function isInteger (s)
{
    return reInteger.test(s)
}
function isMonth (s)
{
    let newS ='';
    if (s[0]==="0") newS = s[1];
    else newS = s;
    return isIntegerInRange (newS, 1, 12);
}

function isDay (s)
{
    let newS ='';
    if (s[0]==="0") newS = s[1];
    else newS = s;
    return isIntegerInRange (newS, 1, 31);
}

function isYear (s)
{
    return (s.length === 4);
}
function daysInFebruary (year)
{
    return (  ((year % 4 === 0) && ( ((year % 100 !== 0)) || (year % 400 === 0) ) ) ? 29 : 28 );
}
function stripLeadingZerosFromIntString (s)
{
    if (s ==="") { return s; }
    let newS = "";
    let strippingLeadingZeros = true;
    for ( const j of s)
    {
        if ((j==="0") && (strippingLeadingZeros !== false))
        {
            continue;
        }
        else
        {
            strippingLeadingZeros = false;
            newS += j;
        }
    }
    console.log("newsssss"  +newS)
    return newS;
}
function isDate (month, day, year)
{
    let newMonth    = stripLeadingZerosFromIntString(month);
    let newDay      = stripLeadingZerosFromIntString(day);
    let newYear     = stripLeadingZerosFromIntString(year);
    console.log(newMonth  + "  " +newDay +"  "+ newYear)
    if ((newMonth.length === 0) || (newDay.length === 0) || (newYear.length === 0))
    {
        console.log("fails here!!")
        return false;
    }

    if (!(isMonth(newMonth) && isDay(newDay) && isYear(newYear)))
    {
        console.log("fails here!!")
        return false;
    }

    let intMonth    = parseInt(newMonth);
    let intDay      = parseInt(newDay);
    let intYear     = parseInt(newYear);

    if (intDay > daysInMonth[intMonth-1])
    {
        console.log("fails here!!")
        return false;
    }

    if ((intMonth === 2) && (intDay > daysInFebruary(intYear)))
    {
        console.log("fails here!!")
        return false;
    }
    return true;
}
