import serviceAxiosInstance from "../config/api.service.config";
import {ENDPOINTS} from "../constants/endpoints";
import axios from "axios";

export function getAssignmentInfo(teamWrkBpiNb , cntrctrNb){
    return serviceAxiosInstance.get(ENDPOINTS['GET_ASSIGNMENT_INFO'] + '/' + teamWrkBpiNb + '/' + cntrctrNb);
}
export function getPersonalPropertyFUP(cntrctrNb){
    return serviceAxiosInstance.get(ENDPOINTS['GET_PERSONAL_PROPERTY_FUP'] + '/' + cntrctrNb);
}
export function getSummary(cntrctrNb){
    return serviceAxiosInstance.get(ENDPOINTS['GET_SUMMARY'] + '/' + cntrctrNb);
}
export function getBuyerCobuyerInfo(vinNb){
    return axios.get("https://localhost:3030/RepoWebExt/" + ENDPOINTS['GET_BUYERCOBUYER_INFO'] + vinNb);
}
export function getVechileDsiposalInfo(){

    return axios.get("https://localhost:3030/RepoWebFCNA/"+ "api/v1/vehicleDisposal/7201/2A/347910000");
}
