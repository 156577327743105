import React, {useEffect, useState} from "react";
import {AssignmentService} from "../../Services/AssignmentService";
import {getUserInfoFromSession} from "../../Services/session.mgmt";

function AssignmentDetailsHeader(props){
    const teamWrkBpiNb= props.bpiNo
    const [assignmentName] = useState("Ford Motor Credit company Repossession Assignment")
    const userInfo = getUserInfoFromSession();
    const [localError, setLocalError] = useState('')
    const [assignmentDetails, setAssignmentDetails] = useState([])
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getAssignmentData = async ()=>{
            try{
                const assignmentResp = await AssignmentService.getAssignmentInfo(teamWrkBpiNb, userInfo.cntrctrNb);
                setAssignmentDetails(assignmentResp.data)
            }catch (err){
                // If an error occurs, handle it and set a custom message
                if (err.response) {
                    // Server responded with a status other than 200 range
                    setLocalError(`Error: ${err.response.status} - ${err.response.data.message || 'Something went wrong'}`);
                } else if (err.request) {
                    // Request was made but no response was received
                    setLocalError('Error: No response received from the server.');
                } else {
                    // Other errors like setting up the request
                    setLocalError(`Error: ${err.message}`);
                }
            }finally {
                setLoading(false);
            }
        };
        getAssignmentData();

    }, [teamWrkBpiNb]);
    if(loading) return <>Loading...</>;
    return(
        <>
            <div>
                {localError ? (
                    <div className="error-message" style={{color: 'red'}}>
                        {localError}
                    </div>
                ) : (
                    <div className="data-content">
            {assignmentDetails.map((assignmentDetails) => {
                return (
                    <section
                        className="fmc-global-attribute-bar fmc-global-attribute-bar--vr-md fmc-px-3 fmc-pb-3-sm fmc-py-3-md fmc-py-3-lg fmc-py-3-xl fds-color__bg--white"
                        key={assignmentDetails.teamwkrBpiNb}>
                        <p>{assignmentName}</p>
                        <div className="fds-layout-gri p-0">
                            <div className="fds-layout-grid__inner p-0 ">
                                <div className="fds-layout-grid__cell--span-2">{assignmentDetails.custAcctNb}</div>
                                <div
                                    className="fds-layout-grid__cell--span-5">{assignmentDetails.vehMdlYr} {assignmentDetails.vehColorTx} {assignmentDetails.makeDescTx} {assignmentDetails.mdlDescTx} {assignmentDetails.vehBodyStyleTx}</div>
                                <div
                                    className="fds-layout-grid__cell--span-3">{assignmentDetails.vinSerialNb} {assignmentDetails.vehRcvrdStAbbrCd} {assignmentDetails.lcnsPlateStAbbrCd}</div>
                                <div
                                    className="fds-layout-grid__cell--span-2">{assignmentDetails.keyIgntnNb} {assignmentDetails.keyTrunkNb}</div>
                            </div>
                        </div>

                    </section>
                );

            })}
                    </div>


                )}
            </div>
        </>
    )
}

export default AssignmentDetailsHeader;